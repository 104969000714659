import React, { useState,useEffect } from "react";
import styled from "styled-components";
import { Link, useHistory,useParams } from "react-router-dom";
import { Container, Row, Col, Image } from "react-bootstrap";

import config from '../../config/config';
import { Display } from "react-bootstrap-icons";
import ReactToPdf from "react-to-pdf";

function Invoice() {
  const history = useHistory();
  const { id } = useParams();
  let user=JSON.parse(localStorage.getItem("uid"));
  let invoice="Invoice_"+id+".pdf";
  const [order, setOrder] = useState([]);

  //console.log(id);
  useEffect(() => {
  fetch(config.API()+"api/Apiinvoice/"+id)
  .then((response) =>response.json())           
  .then((data) => {       
    setOrder(data);        
  })
},[])

const ref = React.createRef();
const options = {
    orientation: 'portrait',
    unit: 'in',
    format: [8.3,11.7]
};

//console.log(order.OrderDetails);

return (
  <Styles style={{ background: "#ccbdbd"}}>
  <Container style={{width:"786px", paddingTop:"10px", paddingRight:"80px", paddingLeft:"40px",background:"#FFF" }} ref={ref} title="Order History" subTitle="Order History" browserTitle="Order History">
   
   <Row>    
   <div className="row" style={{display:"block"}}>
      <Col md={6}>
        <h2>Invoice</h2>
        <p>
        <b>Customer Name</b> {order.Customer?.CustomerName}
        </p>
        <p>
         <b> Billing Address</b> {order.Customer?.BillingAddress}
        </p>
        <p>
         <b>Phone: </b> {order.Customer?.PhoneNumber}
        </p>

      </Col>
      <Col md={6}>
        <Link to="/">
        <img  style={{"width":"100%"}} className="pull-right" src="/images/logo.png" />
        </Link>
      </Col>
    </div>
    <div className="row" style={{display:"block"}}>
      <Col md={7}>
        <h2>Delivary Address</h2>
        <p>
        <b>Address: </b> {order.Address}
        </p>
        <p>
         <b>Postal Code: </b> {order.PostalCode}
        </p>
        <p>
         <b>Country: </b> {order.Country}
        </p>

        <p>
         <b>City: </b> {order.City}
        </p>
        <p>
         <b>Alternet Phone: </b> {order.AlterPhone}
        </p>


      </Col>
      <Col md={5}>
        <div className="pull-right">
      <h2>Order Details</h2>
        <p>
          <b>Order Date: </b> {order.Created}
        </p>
        <p>
          <b>Invoice Id: </b> {order.OrderId}
        </p>
        <p>
          <b>Payment Mode: </b>{order.Payment_Mode}
        </p>
        <p>
          <b>Payment Status: </b>{order.Payment_Status==0 ? "Pending":order.Payment_Status}
        </p>
        </div>
      </Col>
    </div>
    <hr/>
   <Col xs={12}>
   <div title="Order History" type="primary" closable collapsable >
   <table class="table" >  
              <thead class="thead-dark">  
                  <tr>  
                    <th>SL#</th>
                      <th scope="col">Name</th>  
                      <th scope="col">SKU</th>  
                      <th scope="col">Quantity</th>  
                      <th scope="col">Rate</th>  
                      <th scope="col">Price</th>  
                     
                    
                  </tr>  
              </thead>  
              <tbody style={{fontSize:"12px"}}>  
                  {order.OrderDetails && order.OrderDetails.map((item,key) => {  
                      return <tr key={item.ProductID}>  
                          <td>{key+1}</td>
                          <td>{item.ProductName}</td>  
                          <td>{item.Sku}</td>  
                          <td>{item.Quantity}</td>  
                          <td>{Number(item.Price).toFixed(2)}</td>  
                          <td>{Number(item.Price*item.Quantity).toFixed(2)}</td>  
                       
                      </tr>  
                  })}  
              </tbody>  
              <tfoot>
                <tr>
                    <td colspan="4"> </td>
                    <td>Sub Total</td>
                    <td>{order.OrderDetails?.reduce((a,v) =>  a = a + v.Price*v.Quantity , 0 )}</td>
                </tr>
                <tr>
                    <td colspan="4"> </td>
                    <td>Delivery Charge</td>
                    <td>{order.ShippingCost}</td>
                </tr>
                <tr>
                    <td colspan="4"> </td>
                    <td>Discount</td>
                    <td>{order.Discount}</td>
                </tr>
                <tr>
                    <td colspan="4"> </td>
                    <td>Coupon Discount</td>
                    <td>{order.CouponDiscount}</td>
                </tr>
                <tr>
                    <td colspan="4"> </td>
                    <td>Grand Total (Including VAT 5%)</td>
                    <td>{Number(order.TotalCost).toFixed(2)}</td>
                </tr>
            </tfoot>
          </table>  

      </div>
      </Col>
      </Row>
  </Container>
  <Container>
    <Row>
      <Col  md={10}>
      <ReactToPdf targetRef={ref} filename={invoice} options={options} x={.2} y={.2} scale={1}>
          {({toPdf}) => (
              <button className="btn btn-primary pull-right" onClick={toPdf}>Download</button>
          )}
      </ReactToPdf>
      </Col>
    </Row>
    </Container>
   
  </Styles>
);
}

export default Invoice;


const Styles = styled.div`
  
  .row {
   display:block;
  
}
`


