import React from "react";
import styled from "styled-components";
import getData from "../../Getdata";
import { Link } from "react-router-dom";

import { Container, Row, Col, Image } from "react-bootstrap";
import config from '../../config/config';
//import { Height } from "@material-ui/icons";

function Row5() {
  const { category } = getData("categories");
  return (
  <Styles>
  {/* <Container style={{padding:"0px"}}> */}
      
         
             {/* <Col  md={6}>
               
                <a href="https://www.tradeforhope.com/">
                  <Image style={{height:"200px", width:"100%"}} src="images/other.png" thumbnail  />
                </a>
              </Col> */}
        
              {/* <Col  md={6}>
                
                  <a href="https://sallyannbd.com/product/Home-&-Kitchen/">
                  <Image style={{height:"200px", width:"100%"}} src="images/sallyan.png" thumbnail  />

                  </a>

              </Col>
         */}
        
        {/* </Row> */}
       {/* </Container> */}
     </Styles>
   );
}

export default Row5;

const Styles = styled.div`
  .img-thumbnail {
    border: none;
  }
`;
