import React from "react";
import styled from "styled-components";
//import getData from "../../Getdata";
import { Link } from "react-router-dom";

import { Container, Row, Col, Image } from "react-bootstrap";
//import config from '../../config/config';

function Row4() {
  //const { banner } = getData("regional");
  return (
    <Styles>
      <Container>
        <Row>
          {/* {banner &&
            banner.filter(ban=>ban.type=="regional").map((doc) => ( */}

              <Col style={{ paddingBottom: "1rem" }} xs={12}>
                <Link to="/">
                  <Image alt="Regional"  src="/images/centergallery.jpg" thumbnail />
                </Link>
              </Col>

            {/* ))} */}
        </Row>
    
      </Container>
    </Styles>
  );
}

export default Row4;

const Styles = styled.div`
  .img-thumbnail {
    border: none;
  }
  .ftext{
    margin-top:5px;padding-top:5px;
 
    font-size: 1.5rem;
    text-align: center;
    padding: 30px;
  }
`;
