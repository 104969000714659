import React from "react";
import styled from "styled-components";
import getData from "../../Getdata";
import { Link } from "react-router-dom";

import { Container, Row, Col, Image } from "react-bootstrap";
import config from '../../config/config';


function Mission() {
  const { basics } = getData("basics");
  let about= basics.find(s=>s.BasicTypeId==2);
  return (
    <Styles>
      <Container style={{padding:"0px"}}>
      
         
       
              <Row>
              <Col md={12}>
                <p  style={{textAlign:"center"}}>
                {about?.Content}
                </p>

              </Col>
              {/* <Col  style={{ paddingBottom: "1rem" }} md={4}>
                <Link to="/">
                  <Image  src={config.API()+about?.ImageURL} thumbnail  />
                </Link>
              </Col> */}
              </Row>
           
        
        {/* </Row> */}
      </Container>
    </Styles>
  );
}

export default Mission;

const Styles = styled.div`
  .img-thumbnail {
    border: none;
  }
`;
