import { useState, useEffect } from "react";
import config from './config/config';




function GetCat(collection) {
    //  const [docs, setDocs] = useState([]);
    
      const [category, setCategory] = useState([]);
     
      const [loading, setLoading] = useState(false);
  
  
      useEffect(() => {
          setLoading(true);
          fetch(config.API()+"api/Apicategory")
          .then((response) =>response.json())           
          .then((data) => {       
              
               
                setCategory(data.categories);                
                setLoading(false);
                
               
                 
          })
  
      }, [collection]);
  
      return { category,loading};
  }
  
  export default GetCat;
