import React, { useState } from "react";
import AdminLTE, { Sidebar, Content, Row, Col, Box, Button } from 'adminlte-2-react'
import { Link, useHistory } from "react-router-dom";
import config from '../config/config';

function Setting() {
  const history = useHistory();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const changePassword = (e) => {
    e.preventDefault();
    if (password === confirmPassword) {
      
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        var urlencoded = new URLSearchParams();
        urlencoded.append("CustomerName",firstName + " " + lastName);
        urlencoded.append("Email", email);
        urlencoded.append("PhoneNumber", phone);
        urlencoded.append("Password", password);

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: urlencoded,
          redirect: 'follow'
        };

        fetch(config.API()+"api/Signup", requestOptions)
          .then(response => response.text())
          .then(result =>{
            history.push('/verification');
          })
          .catch(error => console.log('error', error));
    } else {
      alert("Password must be same");
    }
  };

  return (
    <Content title="Setting" subTitle="Change Password" browserTitle="Setting">
     
     <Row>
       

     <Col xs={8}>
     <Box title="Change Password" type="primary" closable collapsable >
       
     
          <form action="#" className="form-horizontal">
            
            <div className="form-group">
              <label for="inputName" class="col-sm-4 control-label">Password</label>
              <div className="col-sm-8">
              <input
                className="form-control"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type="type"
                  placeholder="Password"
                />
                
              </div>
            </div>
          
            <div className="form-group">
              <label for="inputName" class="col-sm-4 control-label">Confirm Password</label>
              <div className="col-sm-8">
              <input
                className="form-control"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                type="text"
                placeholder="Confirm Password"
              />
              </div>
            </div>

           
          
            <button className="btn btn-primary float-right" onClick={changePassword}>Save Change</button>
          
          </form>
      
        </Box>
        </Col>
        </Row>
    </Content>
  );
}

export default Setting;
