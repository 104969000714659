import React from "react";
import * as Icon from 'react-bootstrap-icons';

function Shopping() {
  

  const shopping = () => {

  window.location.href="/";

  };

  return (
    
          
            <button className="btn btn-primary"  onClick={shopping}><Icon.Heart /> &nbsp; Continue Shopping</button>
        
  );
}

export default Shopping;
