import { useState, useEffect } from "react";
import config from './config/config';


function GetPro(collection) {
  
      const [product, setProduct] = useState([]);
     
      const [loading, setLoading] = useState(false);
  
  
      useEffect(() => {
          setLoading(true);
          fetch(config.API()+"api/Apiproduct")
          .then((response) =>response.json())           
          .then((data) => {       
                             
                setProduct(data.products);
                setLoading(false);
                
               
                 
          })
  
      }, [collection]);
  
      return { product,loading};
  }
  
  export default GetPro;

  