import React, { useEffect } from "react";

import { BrowserRouter as Router, Switch, Route, Link ,useHistory} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import styled from "styled-components";
import { useStateValue } from "./context/State";


// COMPONENTS

import Footer from "./components/Footer";
import Home from "./components/Home";
import MenClothing from "./components/productComponents/MenClothing";
import WomenClothing from "./components/productComponents/WomenClothing";
import MobileCovers from "./components/productComponents/MobileCovers";
//import AllClothing from "./components/productComponents/AllClothing";
import Products from "./components/productComponents/Products";
import SearchProducts from "./components/productComponents/SearchProducts";
import Events from "./components/productComponents/Events";
import Checkout from "./components/checkouts/Checkout";
import Login from "./components/subHeader/Login";
import Signup from "./components/subHeader/Signup";
import Wishlist from "./components/checkouts/Wishlist";
import Verification from "./components/subHeader/Verification";
import ForgetPassword from "./components/subHeader/ForgetPassword";
import Admin from "./admin/Admin";
import ProductDetails from "./components/productComponents/ProductDetails";
import Invoice from "./components/checkouts/Invoice";
import About from "./components/About";
import Story from "./components/Story";
import Cancel from "./components/checkouts/Cancel";
import Fail from "./components/checkouts/Fail";
import Contact from "./components/Contact";
import TermsCondition from "./components/TermsCondition";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ReturnPolicy from "./components/ReturnPolicy";
import { useMediaQuery } from 'react-responsive';

import BottomNav from "./components/BottomNav";




function App() {
 
  const [{}, dispatch] = useStateValue();
  const authUser = JSON.parse(localStorage.getItem('uid'));
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
  }

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 })
    return isDesktop ? children : null
  }



  useEffect(() => {
     
   
      if (authUser!=null) {
        dispatch({
          type: "SET_USER",
          user: authUser,
        });
      } else {
        dispatch({
          type: "SET_USER",
          user: null,
        });
      }
   
  }, [dispatch]);

  return (
    <Router>
      <Switch>
        <Route exact path="/login">
          <Login />
        </Route>
        <Route exact path="/signUp">
          <Signup />
        </Route>
        <Route exact path="/invoice/:id">
          <Invoice />
        </Route>
        <Route path="/coming-soon">
          <ComingSoon>
            <div className="card">
              <div
                style={{
                  background: "#d9dadb",
                  textTransform: "uppercase",
                  padding: "2rem 0",
                }}
              >
                <strong>
                  Some features are under construction will be here soon.......
                </strong>
              </div>
              <img
                alt="coming-soon"
                src="https://image.freepik.com/free-vector/group-people-shouting-megaphone-with-coming-soon-word-illustration-concept_78434-97.jpg"
              />
              <Link className="home" to="/">
                🏠 HOME
              </Link>
            </div>
          </ComingSoon>
        </Route>
        <>
        
          {/* <Header /> */}
          <Toast>
            <ToastContainer style={{ top: "4rem" }} autoClose={2000} />
          </Toast>
          <div style={{ minHeight: "100vh" }}>
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/product/:slug">
                <Products/>
              </Route>
              <Route path="/searchproduct/:slug">
                <SearchProducts/>
              </Route>
              <Route path="/product/:id">
                <Events />
              </Route>
              <Route path="/productdetails/:slug">
                <ProductDetails/>
              </Route>

              <Route path="/story/:slug">
                <Story/>
              </Route>
              <Route exact path="/men-clothing">
                <MenClothing />
              </Route>
              <Route exact path="/women-clothing">
                <WomenClothing />
              </Route>

              <Route exact path="/about">
                <About />
              </Route>
              <Route exact path="/cancel">
                <Cancel />
              </Route>
                <Route exact path="/fail">
                <Fail />
              </Route>
              <Route exact path="/contact">
                <Contact />
              </Route>
              <Route exact path="/terms-condition">
                <TermsCondition />
              </Route>
              <Route exact path="/privacy-policy">
                <PrivacyPolicy />
              </Route>
              <Route exact path="/return-policy">
                <ReturnPolicy />
              </Route>
              <Route exact path="/mobile-covers">
                <MobileCovers />
              </Route>
              <Route exact path="/cart">
                <Checkout />
              </Route>
              <Route path="/wishlist">
                <Wishlist />
              </Route>
               <Route path="/verification">
                <Verification />
              </Route>
              <Route path="/forgetpassword">
                <ForgetPassword />
              </Route>
              <Route path="/user/dashboard">
                <Admin />
              </Route>
              <Route path="/user/profile">
                <Admin />
              </Route>
              <Route path="/user/setting">
                <Admin />
              </Route>
              <Route path="/user/orderhistory">
                <Admin />
              </Route>
              
            </Switch>
          </div>
          <Footer />
          <Mobile>
           <BottomNav/>
          </Mobile>
        </>
      </Switch>
    </Router>
  );
}

export default App;
const ComingSoon = styled.div`
  text-align: center;

  .card {
    width: 55%;
    margin: 2rem auto;
    @media (min-device-width: 320px) and (max-device-width: 480px) {
      width: 100%;
    }
  }
  .home {
    padding: 1rem;
    text-transform: uppercase;
    text-decoration: none;
    &:hover {
      color: red;
    }
  }
`;
const Toast = styled.div`
  .Toastify__toast {
    margin-bottom: 1rem;
  }
  .Toastify__close-button--default {
    color: #fff;
  }

  .Toastify__toast-container {
    @media (max-width: 990px) {
      width: 15rem;
    }
  }
  .Toastify__toast--default {
    background: rgba(24, 24, 24, 0.8);
    color: #fff;
  }
`;
