import React from "react";
import { Link, useHistory } from "react-router-dom";
import BottomNavigation from 'reactjs-bottom-navigation'
import 'reactjs-bottom-navigation/dist/index.css'
import * as Icon from 'react-bootstrap-icons';
import { useStateValue } from "../context/State";

function BottomNav() {

  const history = useHistory();
  const [{ bag, user,wishlist }] = useStateValue();

  const home = (url) => {
   
    history.push(url);
  }

  const profile = () => {
   
    if(user!=null){
    history.push("/user/profile");
    }
    else{
      history.push("/login");
    }
  }
  const bottomNavItems = [
    {
      title: 'Home',
      icon: <Icon.House />,
      activeIcon:<Icon.House />,
    
      onClick: () =>home("/")
    },

    {
      title: 'Cart',
      icon: <Icon.Cart />,
      activeIcon:<Icon.Cart />,
      onClick: () =>home("/cart")
    },

    {
      title: 'Wishlist',
      icon: <Icon.Heart />,
      activeIcon:<Icon.Heart />,
      onClick: () =>home("/wishlist")
    },
    {
      title: 'Profile',
      icon: <Icon.People />,
      activeIcon:<Icon.People />,
      onClick: () =>profile()//("/user/profile")
     
    }
    ,

    {
      title: 'Menu',

      icon: <Icon.Chat />,

      activeIcon:  <Icon.Chat />,

     
    }
  ]

  return (
    <>
      <BottomNavigation
              items={bottomNavItems}
              defaultSelected={0}
            
            />
    </>
  );
}

export default BottomNav;
