import React from "react";
import * as Icon from 'react-bootstrap-icons';

function Logout() {
  

  const logout = () => {

  localStorage.removeItem("uid");   
 // history.push("/");
 window.location.href="/";

  };

  return (
    
          
            <a href="#"  onClick={logout}><Icon.ArrowRightCircle /> &nbsp; Logout</a>
        
  );
}

export default Logout;
