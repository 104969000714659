import React from "react";
import styled from "styled-components";
import getData from "../Getdata";
import ItemCard from "../components/productComponents/ItemCard";
import { Container, Image } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";

import SiteHeader from "../components/SiteHeader";
import parse from 'html-react-parser';
import { Link ,useParams } from "react-router-dom";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import config from "../config/config";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(0),
    },
  },
}));

function About() {
  const { basics } = getData("basics");
  let about= basics.filter(s=>s.BasicTypeId==4);

  

  return (
    <>
    <SiteHeader/>
    <div>
      <Styles>
          {/* <Fade>
            <Row0 />
          </Fade>
          <Fade>
            <Banner_menu />
          </Fade> */}
         
        <Container style={{marginTop:"90px"}}>
        <div className="row">
             <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          
            <Breadcrumb.Item active>About</Breadcrumb.Item>
          </Breadcrumb>
          </div>
          <div className="row">
           
          
            
            <div className="col-md-6">
            <h3>{about?.Title}</h3>
            </div>
            {about.map((item) => (
              <>
               <br></br>
           
             <div className="row">
              
               <div className="col-md-6">
            <p style={{textAlign:"justify"}}>
             {parse(item?.Content)}
             
            </p>
            </div>
            <div className="col-md-6" style={{"marginTop":"50px"}}>
            <img style={{"width":"100%"}} src={config.API()+item?.ImageURL}/>
            </div>
            </div>
            </>
            ))
}
          </div>
        </Container>
      </Styles>
    </div>
    </>
  );
}

export default About;

const BREADCRUMB = styled.div`
  background: #b5b0b0;
  button {
    padding: 1rem 2rem;
    color: #000;
    font-weight: bolder;
    background: #b5b0b0;
    outline: none;
    &:hover,
    &:focus {
      background: #b5b0b0;
      box-shadow: inset 19px 19px 37px #a19f74, inset -19px -19px 37px #ffffe6;
      outline: none;
    }
  }
  a {
    color: #000;
    text-decoration: none;
    &:hover,
    &:focus {
      color: rgba(255, 255, 255, 0.5);
    }
  }
`;

const CardStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 2.5rem auto 0 auto;

  @media (max-width: 990px) {
    width: 100%;
    margin: auto;
    margin-top: 2.5rem;
    width: 100%;
    justify-content: space-evenly;
  }
`;

const Title = styled.div`
  position: relative;
  top: 1.5rem;
`;

const Styles = styled.div`
  margin-top: 3rem;
`;
