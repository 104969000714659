import React, { useState } from "react";
import styled from "styled-components";

import getData from "../../Getdata";
import { Container, Row, Image ,Button} from "react-bootstrap";


import config from '../../config/config';


import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";


const chevronWidth = 0;

function Client() {   
  const { basics } = getData("basicss");

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    paritialVisibilityGutter: 60
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    paritialVisibilityGutter: 50
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 30
  }
};

  


  return (
    <Styles>
    <Container>
    <Carousel
      swipeable={false}
      draggable={false}
      showDots={true}
      responsive={responsive}
      ssr={true} // means to render carousel on server-side.
      infinite={true}
      autoPlay={"test" !== "mobile" ? true : false}
      autoPlaySpeed={3000}
      keyBoardControl={true}
      customTransition="all .5"
      transitionDuration={500}
      containerClass="carousel-container"
      removeArrowOnDeviceType={["tablet", "mobile"]}
      deviceType="desktop"
      dotListClass="custom-dot-list-style"
      itemClass="carousel-item-padding-40-px"
    >
  { basics &&  basics.filter(s=>s.BasicTypeId==3).map((_, i) =>
      <div>
        <center>
        <img  src={config.API()+_.ImageURL}/>
        </center>
      </div>
    )
  }

</Carousel>;
   
    </Container>
  </Styles>
  );
}

export default Client;
const Wrapper = styled.div`
    padding: 0 ${chevronWidth}px;
    max-width: 1000px;
    margin: 0 auto;
  `;
  
  const SlideItem = styled.div`
    height: 245px;
    background: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
  `;
const Styles = styled.div`
  .img-thumbnail {
    border: none;
  }
  .imageOver {
    position: relative;
    text-align: center;
    color: white;
  }
  
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    padding:5px;
    background-color:#fff;
    transform: translate(-50%, -50%);
    color:#000;
  }
`;
