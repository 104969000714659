import React, { useState, useEffect } from "react";
import AdminLTE, { Sidebar, Content, Row, Col, Box, Button } from 'adminlte-2-react'
import { Link, useHistory } from "react-router-dom";
import Moment from 'react-moment';

import config from '../config/config';

function OrderHistory() {
  let user=JSON.parse(localStorage.getItem("uid"));

  const [order, setOrder] = useState([]);
  const  invoiceDetails=(id)=> {
   // window.location.href="/invoice/"+id;
    const win = window.open('/invoice/'+id, "_blank");
    win.focus();
 
  }


  //console.log(user);
  useEffect(() => {
  fetch(config.API()+"api/Postorder/"+user.CustomerId)
  .then((response) =>response.json())           
  .then((data) => {       
    setOrder(data);
  //  console.log(data);
       
         
  })
},[])
  

  return (
    <Content title="Order History" subTitle="Order History" browserTitle="Order History">
     
     <Row>    

     <Col xs={12}>
     <Box title="Order History" type="primary" closable collapsable >
     <table class="table" >  
                <thead class="thead-dark">  
                    <tr>  
                        <th scope="col">SL</th>  
                        <th scope="col">Invoice No</th>  
                        <th scope="col">Date</th>  
                        <th scope="col">Payment Mode</th>  
                        <th scope="col">Total Amount</th>  
                    
                        <th scope="col">Order Status</th>  
                        <th scope="col">ETA</th>  
                        <th scope="col">Action</th>  
                       
                      
                    </tr>  
                </thead>  
                <tbody>  
                    {order.map((item,key) => {  
                        return <tr key={key}>  
                        <td>{key+1}</td>
                            <td>{item.OrderId}</td>  
                            <td>
                            
                            <Moment format="YYYY-MM-DD">
                              {item.Created}
                            </Moment></td>  
                            <td>{item.Discount}</td>  
                            <td>{item.TotalCost}</td>  
                            
                            <td>{item.Status}</td>  
                            <td>
                            <Moment format="YYYY-MM-DD">
                                2022-04-19T12:59-0500
                            </Moment>
                            </td>

                            <td><button className="btn btn-primary"   onClick={(e) => invoiceDetails(item.OrderId)}>Details</button></td>  
                         
                        </tr>  
                    })}  
                </tbody>  
            </table>  
        </Box>
        </Col>
        </Row>
    </Content>
  );
}

export default OrderHistory;
