import React from "react";
import styled from "styled-components";
import {
  AccountCircle,
  Favorite,
  LocalMall,
  ExitToApp,
  History,
  Notifications,
  Android,
} from "@material-ui/icons";
import {
  Badge,
  Fab,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Divider,
} from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import { Form, InputGroup, FormControl, Button } from "react-bootstrap";
import { useStateValue } from "../../context/State";
import { auth } from "../../firebase";
import '../../index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import { Container, Row, Col, Image } from "react-bootstrap";
import getCat from "../../Getcat";


const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 2,
    top: 2,
    padding: "0 4px",
    fontWeight: "bolder",
    fontSize: "12.5px",
    minWidth: "18px",
    height: "18px",
    background: "rgba(253, 58, 103, 0.836)",
  },
}))(Badge);
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginTop: "1rem",
  },
}));

const MenuNav = ({ open }) => {
  const classes = useStyles();
  const history = useHistory();
  const [{ bag, user }] = useStateValue();
  const anchorRef = React.useRef(null);
  const [pop, setPop] = React.useState(false);

  const { category } = getCat("category");

  const handleToggle = () => {
    setPop((prevPop) => !prevPop);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setPop(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setPop(false);
    }
  }

  const prevOpen = React.useRef(pop);
  React.useEffect(() => {
    if (prevOpen.current === true && pop === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = pop;
  }, [pop]);

  const handleLogout = () => {
    if (user) {
      auth.signOut();
      history.push("/");
    }
  };
  return (
  
    <Div open={open}>
   
        {/* <Link to="/men-clothing">
          <span className="menu">MEN</span>
        </Link>
        <Link to="/women-clothing">
          <span className="menu">WOMEN</span>
        </Link>
        <Link to="/mobile-covers">
          <span className="menu">MOBILE COVERS</span>
        </Link> */}  
 <DIV>
 {/* <a href="#" class="navbar-brand font-weight-bold d-block d-lg-none">MegaMenu</a>
      <button type="button" data-toggle="collapse" data-target="#navbarContent" aria-controls="navbars" aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler">
              <span class="navbar-toggler-icon"></span>
          </button> */}

          
<div id="navbarContent" className="navbar-collapse collapse show  "> 

        <ul className="navbar-nav mx-auto">
        
        {category &&
            category.map((tagg) => (
           <li className="nav-item">
            <Link className="nav-link font-weight-bold" style={{"borderRight":"1px solid"}} to={`/product/${tagg.Slug}/`}>
                {tagg.CategoryName }
              </Link>
            </li>
            ))}


         
        {/* <li className="nav-item hv">
          
          <Link className="nav-link font-weight-bold" to="/">
          |
        </Link>
        </li>  */}

        {/* <li className="nav-item hv">
          
          <Link className="nav-link font-weight-bold" to="/login">
          Login
        </Link>
        </li> */}

        {/* <li className="nav-item hv">
          
          <Link className="nav-link font-weight-bold" to="/signUp">
          NEW CUSTOMER
        </Link>
        </li>
      */}

        {/* <li className="nav-item hv">
          
          <a className="nav-link font-weight-bold" target="_blank" href="https://www.facebook.com/sallyannbangladesh">
          <Image padding="0px" src="https://usa.tradeforhope.com/common/prodesc/images/FB3.png"/>
          </a>
        </li> */}

        
      {/* <li className="nav-itme hv">
      <a className="nav-link font-weight-bold" to="/">
      <Image padding="0px" src="'https://connect.facebook.net/en_US/fbevents.js"/>
   
      </a>
      </li> */}
        </ul>
      </div>
    </DIV>
   
     
     
    </Div>
  );
};

export default MenuNav;

const Div = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex: 1;
  padding-left: 3%;

  .loginlogout {
    display: none;
    @media (max-width: 990px) {
      display: flex;
    }
  }
  .hv a:hover{

  }
 
  .menu {
    list-style: none;
    display: flex;
    flex-flow: row nowrap;

    color: #000;
    font-size: 70%;
    font-weight: bold;

    padding-right: 0.5rem;
    padding-left: 0.5rem;
    @media (max-width: 990px) {
      padding: 1rem 0;
    }
  }
  li {
    /* padding: 0 10px; */
    color: #000;
  }
  @media (max-width: 990px) {
    flex-flow: column nowrap;
    background-color: #ffff;
    position: fixed;
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
    top: 0;
    right: 0;
    height: 100vh;
    width: 300px;
    padding-top: 4rem;
    padding-left: 0.5rem;
    transition: transform 0.3s ease-in-out;

    form {
      position: absolute;
    }
  }
`;
const IconGroup = styled.div`
  padding-left: 0.9rem;
  display: flex;
  align-items: center;
  flex-basis: 120px;
  justify-content: space-between;
  border-left: 3px solid #444444;
  @media (max-width: 990px) {
    border: none;
    display: grid;
    padding-left: 0;
    margin-top: 7rem;
    flex-basis: 270px;
  }
  li {
    font-size: 1.2rem;
    font-weight: bolder;
  }
  a,
  button {
    &:hover {
      text-decoration: none;
      color: #000;
    }
    &:focus {
      outline: none;
    }
  }
`;
const Icon = styled.span`
  .fab {
    margin-left: 0.4rem;
    background: rgba(255, 255, 255, 0.1);
    background-clip: padding-box;
    backdrop-filter: blur(40px);
    -webkit-backdrop-filter: blur(40px);

    &:hover {
      background: rgba(255, 255, 255, 0.5);
    }
    &:active {
      background: rgba(255, 255, 255, 0.5);
      transform: translateY(3px);
    }
  }

  @media (max-width: 990px) {
    display: none;
  }
`;
const SPAN = styled.div`
  display: none;
  text-transform: uppercase;
  margin-bottom: 1rem;
  @media (max-width: 990px) {
    display: grid;
    color: #000;
    font-size: 1rem;
    font-weight: bold;
    border-bottom: 3px solid #9e4446;
    width: max-content;
  }
`;

const DIV = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  @media (max-width: 990px) {
    display: grid;
    position: relative;
    top: 4rem;
    flex: 0.1;
    padding-left: 0;
  }

  a,
  span {
    text-decoration: none;
    &:hover {
      /* text-decoration: none; */
      color: #fd3a69;
    }
  }
`;
const Space = styled.div`
  width: 14%;
  /* height: 100%; */
  @media (min-device-width: 320px) and (max-device-width: 480px) {
    width: 0;
    height: 0;
  }
  @media (min-device-width: 768px) and (max-device-width: 1024px) {
    width: 0;
    height: 0;
  }
`;
