import React, { useState } from "react";
import AdminLTE, { Sidebar, Content, Row, Col, Box, Button } from 'adminlte-2-react'
import { Link, useHistory } from "react-router-dom";
import config from '../config/config';
import getGen from "../Getgen";

function Profile() {
  const history = useHistory();
  let user=JSON.parse(localStorage.getItem("uid"));
  const [CustomerName, setCustomerName] = useState(user.CustomerName);
  const {districts, countries } = getGen("country");
  const [isBangladesh, setIsBnangladesh] = useState(true);
  const [phone, setPhone] = useState(user.PhoneNumber);
  const [email, setEmail] = useState(user.Email);
  const [BillingAddress, setBillingAddress] = useState(user.BillingAddress);
  const [ShippingAddress, setShippingAddress] = useState(user.ShippingAddress);
  const [postalcode, setPostalcode] = useState(user.PostalCode);
  const [CountryName, setCountryName] = useState(user.Country);
  const [city, setCity] = useState(user.City);

  const [image, setImage] = useState(user.Pimage);

  //console.log(CountryName);
  
  const  handleSelect=(e)=> {
    setCountryName(e);
    if(e==="BANGLADESH")
    {
      setIsBnangladesh(true);
    }
    else{
     setIsBnangladesh(false);
    }

   }

   const onFileChange = (event) => {
    
    // Update the state
    setImage({ selectedFile: event.target.files[0] });
  
  };
   // On file upload (click the upload button)
   const onFileUpload = () => {   
    
    const formData = new FormData();  
    // Update the formData object
      formData.append(
        "myFile",
        image.selectedFile,
        image.selectedFile.name
      );
      formData.append("Customer_id",user.CustomerId);
  
      var requestOptions = {
        method: 'POST',        
        body: formData,
        redirect: 'follow'
      };

    fetch(config.API()+"api/Image", requestOptions)
    .then(response => response.json())
    .then(result =>{
      localStorage.setItem("uid",JSON.stringify(result));
    let usr=  JSON.parse(localStorage.getItem("uid"));
    setImage(usr.Pimage);
      alert("Submit Successful");
    })
    .catch(error => console.log('error', error));
  };

  const update = (e) => {
    e.preventDefault();   
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var customer=JSON.stringify({
                "CustomerId" : user.CustomerId,
                "CustomerName":CustomerName,
                "BillingAddress":BillingAddress,
                "City":city,
                "Country":CountryName,                
                "Email":email,
                "PhoneNumber":phone,
                "PostalCode":postalcode,
                "ShippingAddress":ShippingAddress,
        });
      //  console.log(user);
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: customer,
          redirect: 'follow'
        };

        fetch(config.API()+"api/Apiprofile", requestOptions)
          .then(response => response.json())
          .then(result =>{
            localStorage.setItem("uid",JSON.stringify(result));
            
            alert("Submit Successful");
          })
          .catch(error => console.log('error', error));
    
  };

  return (
    <Content title="My Profile" subTitle="Update your details" browserTitle="My Prfile">
     
     <Row>
       <Col xs={4}>
       <Box title="My Profile" type="primary" closable collapsable >
     
         <img className="profile-user-img img-responsive img-circle" src={image!=null  ? config.API()+ image:"/images/avatar.png"} alt="User profile picture"/>
      
        <h3 className="profile-username text-center">{CustomerName}</h3>
       
        <p className="text-muted text-center">Customer</p>

        <ul className="list-group list-group-unbordered">
          <li className="list-group-item">
            <b>Total Orders</b> <a class="pull-right">0</a>
          </li>
          <li className="list-group-item">
            <b>Pending Orders</b> <a className="pull-right">0</a>
          </li>
          <li className="list-group-item">
            <b>Canceled Orders</b> <a className="pull-right">0</a>
          </li>
        </ul>
        <div className="form-group">
        <input type="file" onChange={(event)=>{onFileChange(event)}} name="img"/>
        </div>

       
        <button className="btn btn-primary btn-block" onClick={onFileUpload}><b>Save Change</b></button>
      </Box>
    </Col>


     <Col xs={8}>
     <Box title="My Profile" type="primary" closable collapsable >
       
     
          <form action="#" className="form-horizontal">
            <div className="form-group">
              <label for="inputName" class="col-sm-2 control-label">Name</label>
              <div className="col-sm-10">
                <input
                className="form-control"
                  type="text"
                  value={CustomerName}
                  onChange={(e) => setCustomerName(e.target.value)}
                  placeholder="Enter your Name"
                  required
                />
                </div>
              </div>

            <div className="form-group">
              <label for="inputName" class="col-sm-2 control-label">Phone</label>
              <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Phone"
                required
              />
              </div>
            </div>

          
            <div className="form-group">
              <label for="inputName" class="col-sm-2 control-label">Email</label>
              <div className="col-sm-10">
             
              <input
                type="text"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
              />
              </div>
            </div>
           
            <div className="form-group">
              <label for="inputName" class="col-sm-2 control-label">BillingAddress</label>
              <div className="col-sm-10">
              <textarea
              className="form-control"
                value={BillingAddress}
                onChange={(e) => setBillingAddress(e.target.value)}
                type="text"
                placeholder="Address"
              >
                </textarea>
              </div>
            </div>

            <div className="form-group">
              <label for="inputName" class="col-sm-2 control-label">Delivary Address</label>
              <div className="col-sm-10">
              <textarea
              className="form-control"
                value={ShippingAddress}
                onChange={(e) => setShippingAddress(e.target.value)}
                type="text"
                placeholder="Address"
              >
                </textarea>
              </div>
            </div>
            <div className="form-group">
              <label for="inputName" class="col-sm-2 control-label">Postal Code</label>
              <div className="col-sm-10">
              <input
                className="form-control"
                value={postalcode}
                onChange={(e) => setPostalcode(e.target.value)}
                type="text"
                placeholder="Postal Code"
              />
              </div>
            </div>
            <div className="form-group">
              <label for="inputName" class="col-sm-2 control-label">Country</label>
              <div className="col-sm-10">
              <select
                className="form-control"                
                onChange={(e) => handleSelect(e.target.value)}                 
              >
                {countries &&  countries.map((country) => {
                   return  <option  selected={CountryName == country.Name} key={country.Id} value={country.Name}>{country.Name}</option>
                  })
                }
                </select>
              </div>
            </div>
            {
              isBangladesh==true ? (
                <div className="form-group">
                <label for="inputName" class="col-sm-2 control-label">City</label>
                <div className="col-sm-10">
                <select
                  className="form-control"                
                  onChange={(e) => setCity(e.target.value)}                 
                >
                  {districts &&  districts.map((district) => {
                     return  <option key={district.Id} selected={city == district.Name} value={district.Name}>{district.Name}</option>
                    })
                  }
                  </select>
                </div>
              </div>
              ):
              (
                <div className="form-group">
                <label for="inputName" class="col-sm-2 control-label">City</label>
                <div className="col-sm-10">
                <input
                  className="form-control"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  type="text"
                  placeholder="City"
                />
                </div>
              </div>
              )
            }
           
          
          
            <button className="btn btn-primary float-right" onClick={update}>Save Change</button>
          
          </form>
      
        </Box>
        </Col>
        </Row>
    </Content>
  );
}

export default Profile;
