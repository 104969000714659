import React, { useState } from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import { Email, LocationCity, Lock, Person,Phone } from "@material-ui/icons";
import config from '../../config/config';
import getGen from "../../Getgen";
import getData from "../../Getdata";
import { Container, Image,Modal, Button  } from "react-bootstrap";
import parse from 'html-react-parser';

function Signup() {
  const history = useHistory();
  const [firstName, setFirstName] = useState("");
  const [code, setCode] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [country, setCountry] = useState("");

  const {countries } = getGen("country");
  
  const { basics } = getData("basics");
  let terms= basics.find(s=>s.BasicTypeId==5);


  //terms
  
  const [ischeck, SetIscheck] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () =>{
     setShow(false);
     SetIscheck(true);
  }

  const handleShow = () => {  
    if(ischeck==true) {
    SetIscheck(false);
   
    }
    else{
      SetIscheck(true);
      setShow(true);
    }
   // 
  };


  const  handleSelect=(e, c)=> {
    setCode(e);
    setCountry(c);

   }

  const signUp = (e) => {
    e.preventDefault();
    if(ischeck){
    if (password === confirmPassword) {
      
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        var urlencoded = new URLSearchParams();
        urlencoded.append("CustomerName",firstName);
        urlencoded.append("Email", email);
        urlencoded.append("PhoneNumber", phone);
        urlencoded.append("CountryCode", code);
        urlencoded.append("Country", country);

        //Country
        urlencoded.append("Password", password);

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: urlencoded,
          redirect: 'follow'
        };

        fetch(config.API()+"api/Signup", requestOptions)
          .then(response => response.json())
          .then(result =>{

           let rt=JSON.parse(result);
           // console.log(rt.phone);
            localStorage.setItem('phone',rt.phone);
            if(rt.msg=="Submit Successful"){
              
               history.push('/verification');
            }
          })
          .catch(error => alert("User Already Exist!"));
    } else {
      alert("Password must be same");
    }
  }
  else{
    alert("Please Check Terms and condition");
  }
  };

  return (
    <>
     
      <Styles>
        <div className="content">
          <div className="text">
          <Link to="/">
            <img src="/images/logo.png" width="250" />
            </Link>
            <br/>
            <br/>
           
            <h1>Sign Up</h1>
            {/* <h3> Sally Ann BD </h3> */}
            <p>You're just one step away from signing up</p>
          </div>
          <form action="#">
            <div className="field">
            <Person
                style={{ position: "absolute", bottom: "14px", left: "1rem" }}
              />
              <Input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="Enter your Full Name"
                required
              />
             
            </div>
             <div className="field">
             <LocationCity
                style={{ position: "absolute", bottom: "14px", left: "1rem" }}
              />
             <select
                        className="form-control"  
                          onChange={(e) => handleSelect(e.target.value, e.target.options[e.target.selectedIndex].text)}                 
                        >
                          <option>---Select Country---</option>

                          {countries &&  countries.map((country) => {
                             return  <option   key={country.Id} value={country.PhoneCode}>{country.Name}</option>
                            })
                          }
                          </select>
             </div>
            <div className="field">
               <Phone
                style={{ position: "absolute", bottom: "14px", left: "1rem" }}
              /> 
           
              <input
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Phone"
                
                required
              />
            </div>

            <div className="field">
              <Email
                style={{ position: "absolute", bottom: "14px", left: "1rem" }}
              />
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
              />
            </div>
            <div className="field">
              <Lock
                style={{ position: "absolute", bottom: "14px", left: "1rem" }}
              />
              <input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                placeholder="Password"
              />
            </div>
            <div className="field">
              <Lock
                style={{ position: "absolute", bottom: "14px", left: "1rem" }}
              />
              <input
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                type="password"
                placeholder="Confirm Password"
              />
            </div>
            <div className="mt-4">
             
             <input type="checkbox" checked={ischeck} onClick={handleShow} /> <a href="javascript:void(0)" onClick={handleShow}>Terms & Condition</a>
             
            </div>
            

            <button onClick={signUp}>Sign Up</button>
            <div className="signup">
              Have an account?
              <Link to="/login">Login Now</Link>
            </div>
          </form>
        </div>
      </Styles>

      <Modal show={show} onHide={handleClose}>

               <Modal.Header closeButton>
                <Modal.Title>{terms?.Title}</Modal.Title>
              </Modal.Header> 

              <Modal.Body style={{overflow:"scroll", height:"400px"}}>
              <p style={{textAlign:"justify"}}>
              {parse(terms?.Content!=null? terms?.Content :"")}
              </p>

              </Modal.Body>

              <Modal.Footer>

                <Button variant="secondary" onClick={handleClose}>Close</Button>
               
              </Modal.Footer>

            </Modal>

    </>
  );
}

export default Signup;

const Input = styled.input`
  width: 50%;
  padding: 0.8rem;
  font-size: 14px;
  outline: none;
  border: none;
  color: #595959;
  background: #fff;
  //box-shadow: inset 2px 2px 5px #babecc, inset -5px -5px 10px #ffffff73;

  &:focus ~ label {
    box-shadow: inset 2px 2px 5px #babecc, inset -1px -1px 2px #ffffff73;
  }
  &:nth-child(1) {
    border-radius: 5px 0 0 5px;
  }
  &:nth-child(2) {
    border-radius: 0 5px 5px 0;
  }
`;

const Styles = styled.div`
  display: flex;
  justify-content: center;

  .content {
    width: 400px;
    background: #ffff;
    margin-top:20px;
    border-radius: 5px;
    padding: 40px 30px;
     box-shadow: 0 22px 40px rgba(17, 96, 96, 0.2);
  }
  .name {
    display: flex;
  }
  .text {
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 0.5rem;
    h1 {
      font-size: 22px;
      font-weight: bold;
    }
    h3 {
      font-size: 18px;
      font-weight: bold;
    }
    p {
      font-size: 10px;
    }
  }
  .field {
    height: 50px;
    width: 100%;
    display: flex;
    position: relative;
    margin-top: 20px;
    input,select {
      height: 100%;
      width: 100%;
      padding-left: 45px;
      font-size: 18px;
      outline: none;
      border: 1px solid;
      color: #595959;
      border-radius: 5px;
      // box-shadow: inset 2px 2px 5px #babecc, inset -5px -5px 10px #ffffff73;

      &:focus ~ label {
        box-shadow: inset 2px 2px 5px #babecc, inset -1px -1px 2px #ffffff73;
      }
    }

    .field span {
      position: absolute;
      width: 50px;
      line-height: 50px;
      color: #595959;
    }

    .field label {
      position: absolute;
      top: 50%;
      left: 45px;
      pointer-events: none;
      color: #666666;
      transform: translateY(-50%);
    }

    .field input:focus ~ label {
      opacity: 0;
    }
  }

  button {
    margin: 15px 0;
    width: 100%;
    height: 50px;
    color: #000;
    font-size: 18px;
    font-weight: 600;
    background: #dde1e7;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 2px 2px 5px #babecc, -5px -5px 10px #ffffff73;

    &:focus {
      color: #3498db;
      box-shadow: inset 2px 2px 5px #babecc, inset -5px -5px 10px #ffffff73;
    }
  }

  .signup {
    font-size: 16px;
    color: #595959;
    margin: 10px 0;

    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
        color: #000;
      }
    }
  }
`;
