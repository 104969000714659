import React, { useState } from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import { Lock, Person } from "@material-ui/icons";
import config from '../../config/config';
import { useStateValue } from "../../context/State";
import getData from "../../Getdata";
import { Container, Image,Modal, Button  } from "react-bootstrap";
import parse from 'html-react-parser';

function Login() {
  const history = useHistory();
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [{}, dispatch] = useStateValue();


     //terms
  const { basics } = getData("basics");
  let terms= basics.find(s=>s.BasicTypeId==5);  
   const [ischeck, SetIscheck] = useState(false);
   const [show, setShow] = useState(false);
   const handleClose = () =>{
      setShow(false);
      SetIscheck(true);
   }
 
   const handleShow = () => {   
     SetIscheck(false);
     setShow(true);
   };
 

  const signIn = (e) => {
    e.preventDefault();
    // if(ischeck){
    var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({
            "PhoneNumber":phone,
            "Password":password
        }),
          redirect: 'follow'
        };

        fetch(config.API()+"api/Login", requestOptions)
          .then(response => response.json())
          .then(auth =>{
            if(auth!=null){
            localStorage.setItem("uid",JSON.stringify(auth));
            dispatch({
              type: "SET_USER",
              user:auth
            });
             history.push('/');
          }
          else{
            alert('User Id or Password is Incorrect');
          }
          })
          .catch(error => alert('User Id or Password is Incorrect'));

        // }
        // else{
        //   alert("Please Check Terms and condition");
        // }
  };
  return (
    <>
     
      <Styles>
        <div className="content">
          <div className="text">
            <Link to="/">
            <img src="/images/logo.png" width="250" />
            </Link>
            <br/>
            <br/>
            <h1>Sign In</h1>           
            <p>You're just one step away from signing up</p>
          </div>
          <form action="#">
            <div className="field">
              <Person
                style={{ position: "absolute", bottom: "14px", left: "1rem" }}
              />
              <input
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Phone Number or Email"
                required
              />
            </div>
            <div className="field">
              <Lock
                style={{ position: "absolute", bottom: "14px", left: "1rem" }}
              />
              <input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                placeholder="Password"
              />
            </div>
            {/* <div className="mt-4">
             
             <input type="checkbox" checked={ischeck}/> <a href="javascript:void(0)" onClick={handleShow}>Terms & Condition</a>
             
            </div> */}
            <div className="forgot-pass">
              <Link to="forgetpassword">Forgot Password?</Link>
            </div>
            <button onClick={signIn}>Sign in</button>
            <div className="signup">
              Not a member?
              <Link to="/signUp"> Register now</Link>
            </div>
          </form>
        </div>
      </Styles>

      <Modal show={show} onHide={handleClose}>

               <Modal.Header closeButton>
                <Modal.Title>{terms?.Title}</Modal.Title>
              </Modal.Header> 

              <Modal.Body style={{overflow:"scroll", height:"400px"}}>
              <p style={{textAlign:"justify"}}>
              {parse(terms?.Content!=null? terms?.Content :"")}
              </p>

              </Modal.Body>

              <Modal.Footer>

                <Button variant="secondary" onClick={handleClose}>Close</Button>
               
              </Modal.Footer>

            </Modal>
    </>
  );
}

export default Login;

const Styles = styled.div`
  display: flex;
  justify-content: center;

  .content {
    width: 400px;
    background: #ffff;
    margin-top: 80px !important;
    // border-radius: 15px;
    padding: 40px 30px;
    box-shadow: 0 22px 40px rgba(17, 96, 96, 0.2);
  }
  .text {
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 0.5rem;
    h1 {
      font-size: 22px;
      font-weight: bold;
    }
    h3 {
      font-size: 18px;
      font-weight: bold;
    }
    p {
      font-size: 10px;
    }
  }
  .field {
    height: 50px;
    width: 100%;
    display: flex;
    position: relative;
    input {
      height: 100%;
      width: 100%;
      padding-left: 45px;
      font-size: 18px;
      outline: none;
      border: 1px solid ;
      color: #595959;
      // background: #dde1e7;
       border-radius: 5px;
     // box-shadow: inset 2px 2px 5px #babecc, inset -5px -5px 10px #ffffff73;
      &:focus ~ label {
        box-shadow: inset 2px 2px 5px #babecc, inset -1px -1px 2px #ffffff73;
      }
    }

    &:nth-child(2) {
      margin-top: 20px;
    }

    span {
      position: absolute;
      width: 50px;
      line-height: 50px;
      color: #595959;
    }

    label {
      position: absolute;
      top: 50%;
      left: 45px;
      pointer-events: none;
      color: #666666;
      transform: translateY(-50%);
    }

    .field input:focus ~ label {
      opacity: 0;
    }
  }

  .forgot-pass {
    text-align: left;
    margin: 10px 0 10px 5px;
    a {
      font-size: 16px;
      color: #3498db;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
  button {
    margin: 15px 0;
    width: 100%;
    height: 50px;
    color: #000;
    font-size: 18px;
    font-weight: 600;
    background: #dde1e7;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 2px 2px 5px #babecc, -5px -5px 10px #ffffff73;

    &:focus {
      color: #3498db;
      box-shadow: inset 2px 2px 5px #babecc, inset -5px -5px 10px #ffffff73;
    }
  }

  .signup {
    font-size: 16px;
    color: #595959;
    margin: 10px 0;
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
        color: #000;
      }
    }
  }
`;
