import React,{useState,useEffect} from "react";
import { Container, Card } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";

import styled from "styled-components";
import { getBagTotal } from "../../context/Reducer";
import { useStateValue } from "../../context/State";
import Product from "./Product";
import Row0 from "../subHome/Row0";
import SiteHeader from "../SiteHeader";
import { Fade, Zoom } from "react-reveal";
import Banner_menu from "../subHome/Banner_menu";
import config from '../../config/config';
import { Link, useHistory } from "react-router-dom";
import getGen from "../../Getgen";
import { Label } from "@material-ui/icons";

function Checkout() {

  const history = useHistory();
  const [{ bag },dispatch] = useStateValue();
  const bagLength = bag.length;
  const {districts, countries } = getGen("country");
  const [isBangladesh, setIsBnangladesh] = useState(true);
  const [ischeck1, SetIscheck1] = useState(false);
  const [payment, SetPayment] = useState("Online");
 

  const [ShippingCost, setShippingCost] = useState("0");

  let user=JSON.parse(localStorage.getItem("uid"));
  let address1, postalcode1, CountryName1, city1, alterphone1;
 
  const ischeck1Fun = () => {
    if(ischeck1==true) {
      SetIscheck1(false);
      }
      else{
        SetIscheck1(true);
       
      }   
   
  };

  const isPayment = (e) => {
    
    SetPayment(e.trim());
    console.log(payment);
   
  };
  
  // const ischeck3Fun = () => {
  //   if(ischeck3==true) {
  //     SetIscheck3(false);
  //     }
  //     else{
  //       SetIscheck3(true);
       
  //     }   
  // };

  if(user!=null){
    address1=user.ShippingAddress;
    postalcode1=user.PostalCode;
    CountryName1=user.Country;
    city1=user.City;
    alterphone1=user.PhoneNumber;
    alterphone1="";
  }
      const [address, setAddress] = useState(address1);
      const [postalcode, setPostalcode] = useState(postalcode1);
      const [CountryName, setCountryName] = useState(CountryName1);
      const [city, setCity] = useState(city1);
      const [alterphone, setAlterphobe] = useState(alterphone1);
  

const cart_empty=(e)=>{
  dispatch({
    type: "CLEAR",
    
  });
}

function  rcheck(){

  if(address=="")
  {
    alert("Address is Required");
    return false;
  }
  else if(postalcode==""){
    alert("Postalcode is Required");
  }
  else if(CountryName==""){
    alert("Postalcode is Required");
    return false;
  }
  else if(city==""){
    alert("City is Required");
    return false;
  }
  else if(alterphone==""){
    alert("Alternative Phone is Required");
    return false;
  }
  else if(ischeck1==false){
    alert("Please check Terms, Reutrn  & Refund Policy");
    return false;
  }
  else if(bag.filter(s=>s.status==true).length==0){
    alert("Please Select Products");
    return false;
  }
  else{
    return true;
  }
 
}

const  handleSelect=(e)=> {
  setCountryName(e);
  if(e==="BANGLADESH")
  {
    setIsBnangladesh(true);
  }
  else{
   setIsBnangladesh(false);
  }

 }

 const  districtSelect=(e)=> {
  
  setCity(e);
  if(e.trim()==="Dhaka")
  {
  
    setShippingCost(60);
  
  }
  else{
   
    setShippingCost(120);
  }

 }

 useEffect(() => {   
   if(city!=null){  
  if(city.trim()==="Dhaka")
  {  
    setShippingCost(60);
  
  }
  else{
   
    setShippingCost(120);
  }
   }
  
}, []);


//localStorage.getItem("lang")
  const postData = (e) => {
    e.preventDefault();   
    if(user!=null){
      if(rcheck()){
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    user.ShippingAddress=address;
    user.PostalCode=postalcode;
    user.Country=CountryName;
    user.City=city;
    user.PhoneNumber=alterphone;

    var raw= JSON.stringify({"postorder":bag.filter(s=>s.status==true),"customer":user,ShippingCost:ShippingCost,Payment_Mode:payment, Payment_Status:false,"isUSD":localStorage.getItem("lang")});
    //console.log(raw);
    var requestOptions = {    
        method: 'POST',    
        headers:myHeaders,
        body: raw, 
        redirect: 'follow'
    };

    fetch(config.API()+"api/Postorder", requestOptions)
      .then(response => response.json())
      .then(result => {
       //var data=JSON.parse(result);
      //  dispatch({
      //   type: "CLEAR",
        
      // });
     
       bag.filter(s=>s.status==true).forEach((item,index) => {
        dispatch({
          type: "REMOVE_FROM_BAG",
          id: item.id,
        })
       });
     
     if(payment=="Online"){
      window.location.href=JSON.parse(result).url;
     }
      else{
        const win = window.open('/invoice/'+JSON.parse(result).OrderId, "_blank");
           win.focus();
      }
      })
      .catch(error => console.log('error', error));
    }
    }
    else{
      history.push('/login')
    }
   
}
  
  return (
    <>
       <SiteHeader/>
      {/* <Fade>
            <Row0 />
      </Fade>
      <Fade>
            <Banner_menu />
          </Fade>  */}
    <Container>
      {bagLength === 0 ? (
        <Emptybag>
          <div>
            <img
              alt="duhud"
              src="https://i.pinimg.com/originals/fa/90/cd/fa90cdab2a780306d0c350964c81e391.png"
            />
          </div>
        </Emptybag>
      ) : (
        <Styles>
         
          <div style={{ marginTop: "3.5rem" ,marginBottom: "5rem" }}   >
            {bag.map((item) => (
              <Product
                key={item.id}
                id={item.id}
                image={item.image}
                pName={item.pName}
                price={item.price}
                rating={item.rating}
                originalPrice={item.originalPrice}
                quantity={item.quantity}
              />
            ))}
     <button className="neumorphism mt-3 mr-5" onClick={cart_empty} style={{"float":"right"}}>Empty Cart</button>
          
        {user!=null? (
          <div className="col-md-12 form-horizontal mt-5" >
              <hr/>
             <h2>Delivary Address</h2>
            
              <br/>
              <div className="form-group">
              <label for="inputName" class="col-sm-4 control-label">Address</label>
              <div className="col-sm-8">
              <textarea
              className="form-control"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                type="text"
                placeholder="Road No, Holding No, Area, Address"
              >
                </textarea>
              </div>
            </div>
          
            <div className="form-group">
              <label for="inputName" class="col-sm-4 control-label">Postal Code</label>
              <div className="col-sm-8">
              <input
                className="form-control"
                value={postalcode}
                onChange={(e) => setPostalcode(e.target.value)}
                type="text"
                placeholder="Postal Code"
              />
              </div>
            </div>

            <div className="form-group">
              <label for="inputName" class="col-sm-4 control-label">Country</label>
              <div className="col-sm-8">
              <select
                className="form-control"                
                onChange={(e) => handleSelect(e.target.value)}                 
              >
                {countries &&  countries.map((country) => {
                   return  <option  selected={CountryName == country.Name} key={country.Id} value={country.Name}>{country.Name}</option>
                  })
                }
                </select>
              </div>
            </div>
            {
              isBangladesh ==true? (
                <div className="form-group">
                <label for="inputName" class="col-sm-4 control-label">City</label>
                <div className="col-sm-8">
                <select
                  className="form-control"                
                
                  onChange={(e) => districtSelect(e.target.value)}                     
                >
                  {districts &&  districts.map((district) => {
                     return  <option key={district.Id} selected={city == district.Name} value={district.Name}>{district.Name}</option>
                    })
                  }
                  </select>
                </div>
              </div>
              ):
              (
                <div className="form-group">
                <label for="inputName" class="col-sm-4 control-label">City</label>
                <div className="col-sm-8">
                <input
                  className="form-control"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  type="text"
                  placeholder="City"
                />
                </div>
              </div>
              )
            }
             <div className="form-group">
              <label for="inputName" class="col-sm-4 control-label">Alternative Phone</label>
              <div className="col-sm-8">
              <input
                className="form-control"
                value={alterphone}
                onChange={(e) => setAlterphobe(e.target.value)}
                type="text"
                placeholder="Phone No"
              
              />
              </div>
            </div>

          
              </div>
              )
              :("")

      }
            

          </div>

          <Price>
            <Card className="mb-5">
              <CurrencyFormat
                value={Number(getBagTotal(bag)).toFixed(2)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={""}
                renderText={(value) => (
                  <Card.Header>
                    <strong>Subtotal</strong> (<em>{bagLength} items</em>):
                    <small> </small>
                    <strong className="pull-right">{value}</strong>
                  </Card.Header>
                )}
              />

              <Card.Body>
               
              
                <div className="form-group">
                  <label>Delivery Charge: </label>
                  <strong className="pull-right">{ShippingCost}</strong>
                </div>
                <Card.Text>
                 If you have coupon enter the code here
                
                </Card.Text>
                 <input type="text" placeholder="Coupon Code.." className="form-control mb-3"/>
               

                <button className="neumorphism mb-3">Apply </button>
             
                <CurrencyFormat
                value={parseFloat(Number(getBagTotal(bag)+ShippingCost).toFixed(2))}
                displayType={"text"}
                thousandSeparator={true}
                prefix={""}
                renderText={(value) => (
                  <Card.Header>
                    <strong>Total</strong> (<em>{bagLength} items</em>):
                    <small> </small>
                    <strong className="pull-right">{  value}</strong>
                  </Card.Header>
                )}
              />
                <div className="form-group row mt-2">
                  <div className="col-md-2">
                   <input type="radio" name="rdpayment" value="Cash On Delivary"  onClick={(e) => isPayment(e.target.value)}         />
                  </div>
                  <label className="col-md-10"> Cash On Delivary</label>
                  <div className="col-md-2">
                   <input type="radio" name="rdpayment" value="Online"  onClick={(e) => isPayment(e.target.value)}  />
                  </div>
                  <label className="col-md-10"> Pay Now </label>

                  <div className="col-md-2">
                   <input type="checkbox" onClick={() => ischeck1Fun()} />
                  </div>
                  <div className="col-md-10" style={{textTransform:"uppercase"}}>
                  I HAVE READ AND AGREE TO THE 
                  <a href="/terms-condition" > Terms and Condtions, </a>
                  <a href="/privacy-policy"> Privacy Policy</a>
                    <a href="/return-policy"> And Return & Refund Policy</a>
                  </div>
                  

                  {/* <div className="col-md-2">
                   <input type="checkbox"  onClick={() => ischeck2Fun()} />
                  </div>
             

                  <div className="col-md-2">
                   <input type="checkbox" onClick={() => ischeck3Fun()} />
                  </div> */}
                
                </div>
                <button onClick={postData} className="neumorphism mt-3">Place Order</button>

              </Card.Body>
            </Card>
          </Price>
        </Styles>
      )}
    </Container>
    </>
  );
}

export default Checkout;

const Emptybag = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10%;
  @media (max-width: 990px) {
    margin-top: 20%;
  }
  img {
    object-fit: contain;
    width: 100%;
  }
`;

const Styles = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 4rem;
  @media (max-width: 990px) {
    display: block;
  }

  .neumorphism {
    color: #fff;
    font-weight: 600;
    border: none;
    text-transform: uppercase;
    border-radius: 8px;
    padding: 0.5rem 2rem;
    background: #1e1c19;
    box-shadow: inset 24px 24px 58px #0c0b0a, inset -24px -24px 58px #c5b8a3;
    &:hover {
      box-shadow: 3px 1px 13px #bfbfbf, -3px -1px 13px #ffffff;
    }
    &:focus {
      background-color: #1e1c19;
      outline: none;
    }
    &:active {
      background: #1e1c19;
      box-shadow: 3px 1px 13px #bfbfbf, -3px -1px 13px #ffffff;
      transform: translateY(3px);
    }
  }
`;

const Price = styled.div`
  width: 35%;
  margin-top: 4rem;
  @media (max-width: 990px) {
    width: 100%;
  }
`;

