import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Carousel, Image } from "react-bootstrap";
import config from '../../config/config';
import getData from "../../Getdata";
import { useState, useEffect } from "react";
import parse from 'html-react-parser';
import Row5 from "../subHome/Row5";
import { Fade, Zoom } from "react-reveal";

import Banner_menu from "../subHome/Banner_menu";

function Slide() {
  const [loading, setLoading] = useState(true);
  const { basics} = getData("basics");

  useEffect(() => {     
  
    setLoading(false);
 }, [Row5, Banner_menu]);


  return (
    <>
  
    <Styles style={{marginTop: "7rem"}}>
<Carousel>
{
loading ? (
   <Carousel.Item>
   <Link to="/">
     <Image
       className="d-block w-100"
       src={config.API()+"/Images/Loading.jpg"}
       alt="First slide"
     />
   </Link>
  
 </Carousel.Item>
)
:(
  basics && basics.filter(s=>s.BasicTypeId==1).map((basic) => (
    <Carousel.Item>
      <Link to={`${basic.url}`}>
        <Image
          className="d-block w-100"
          src={config.API()+basic.ImageURL}
          alt="First slide"
        />
      </Link>
      <Carousel.Caption>
        <h1 style={{textAlign:"left"}}>{basic.Title}</h1>
        <p style={{textAlign:"left"}} >{parse(basic.Content.slice(0, 180))}..</p>
        <Link style={{float:"left"}} to={`/story/${basic.Title.toLowerCase().replace(/ /g, '-')}/`} className="btn btn-primary">Learn More..</Link>
      </Carousel.Caption>
    </Carousel.Item>
 
    
    ))
  
    )
    
    }         
     
      </Carousel>
    </Styles>

    {
    loading==false ? (
      <>
     <Fade>
      <Banner_menu />
    </Fade>


     <WhiteSpace></WhiteSpace>
     <Row5 />
     </>
    ):""
}
    </>
  );
}

export default Slide;

const Styles = styled.div`
  .carousel,
  .slide {
    padding: 0 1.1rem;
  }
  .carousel-caption{
    left:10% !important;
    right: 50% !important;
    padding-bottom: 100px !important;
  }
`;
const WhiteSpace = styled.div`
  height: 2.3rem;
  @media (min-device-width: 320px) and (max-device-width: 480px) {
    height: 0.7rem;
  }
  @media (min-device-width: 768px) and (max-device-width: 1024px) {
    height: 1.3rem;
  }
`;
