import React,{useState,useEffect} from "react";
import styled from "styled-components";

import getPro from "../../Getpro";

import { Container, Row, Col,Modal, Image,Button } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";

import { Fade, Zoom } from "react-reveal";
import SiteHeader from "../SiteHeader";
import ItemCard from "./ItemCard";
import { Link,useParams } from "react-router-dom";
import parse from 'html-react-parser';
import { useStateValue } from "../../context/State";
import { Favorite, LocalMall, Star } from "@material-ui/icons";
import { toast } from "react-toastify";
import config from '../../config/config';
import Breadcrumb from 'react-bootstrap/Breadcrumb'



const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(0),
    },
  },
}));

function ProductDetails() {
  let user=JSON.parse(localStorage.getItem("uid"));
 // console.log(user);
 
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const {product } = getPro("product"); 
  const { slug } = useParams();  
  let pro=product.find(s=>s.Slug.toLowerCase()==slug);

  const [{}, dispatch] = useStateValue();

  const [quantity, setQuantity] = useState("1");

  let src="/images/demo.jpg";
 

  const [imgsrc, setImgsrc] = useState("");

  //  useEffect(() => {     
  //          console.log("1");
  //          showimage(src);   
  //  }, [slug]);

   useEffect(() => {     
   // console.log(pro!=undefined ? pro?.ImageList[0]?.Address:"/images/demo.jpg");
   // console.log(pro);
    src=pro!=undefined ? pro?.ImageList[0]?.Address:"/images/demo.jpg";
    showimage(src);  
   
}, [pro]);


  const showimage = (image) => {
    
   setImgsrc(image);

  }
  const Msg = () => (
    <>
      <div style={{ display: "flex" }}>
        <LocalMall fontSize="small" />
        <h6 >&nbsp; Product added to Bag</h6>
      </div>
    </>
  );
  function addToBag(id,pName,price,image) {
    dispatch({
      type: "ADD_TO_BAG",
      item: {
        id: id,
        pName: pName,
        price: price,
        image: image,
        rating: 0,
        originalPrice: 0,
        quantity:quantity,
        status:false
      },
    });
    toast(Msg);
  };

  function addToBuyNow(id,pName,price,image) {
    dispatch({
      type: "ADD_TO_BAG",
      item: {
        id: id,
        pName: pName,
        price: price,
        image: image,
        rating: 0,
        originalPrice: 0,
        quantity:quantity,
        status:false
      },
    });

    window.location.href="/cart";
  //  toast(Msg);
  };

  function addToWishlist(id, pName,price,image){
    dispatch({
      type: "ADD_TO_WISHLIST",
      item: {
        id: id,
        pName: pName,
        price: price,
        image: image,
        rating: 0,
        originalPrice: 0,

       
      },
    });
  };

  //comments
  const [rate, setRate] = useState("");
  const [comments, setComments] = useState("");
  const postReview = (e) => {
    e.preventDefault();   
    if(user!=null){
      if(rate<=5){
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
   

    var raw= JSON.stringify({Rating:rate, ReviewString:comments,ProductId:pro.ProductId,CustomerId:user.CustomerId});
    console.log(raw);
    var requestOptions = {    
        method: 'POST',    
        headers:myHeaders,
        body: raw, 
        redirect: 'follow'
    };

    fetch(config.API()+"api/Apireview", requestOptions)
      .then(response => response.text())
      .then(result => {
      
       alert('Submit Successful');
      })
      .catch(error => console.log('error', error));
    }
    else{
      alert('Rating Must be less then 5');
    }
    }
    else{
      alert('Please Login First');
    }
  }
  return (
    <>
    <SiteHeader/>
    <div>
      <Styles>
          {/* <Fade>
            <Row0 />
          </Fade>
          <Fade>
            <Banner_menu />
          </Fade> */}
        <Container style={{marginTop:"90px"}}>
       
        {product && product.length>0? (
          <>
           <div className="row">
             <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item href={`/product/${pro.Category.Slug}/`}>
              {pro.Category.CategoryName}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{pro.ProductName}</Breadcrumb.Item>
          </Breadcrumb>
          </div>
          <Row>
           
          
            <Col md={4}>            
             
                <img id="idimg" style={{width:"300px"}} src={imgsrc} />
              
            
                <div className="row">
                  <Gallery>
                    {
                      pro.ImageList.length!=null ? pro.ImageList.map((item)=>(
                     
                        <li className="gallery"><img   onClick={() => {showimage(item.Address)}}  style={{width:"70px", padding:"5px"}} src={item?.Address}/></li>
                   
                        
                        )):""
                    }
                  </Gallery>
                  </div>

            </Col>
            <Col md={5}>
              <h2>{pro.ProductName}</h2>             
              
               <p><b>Description </b>{ parse(pro.ProductDescription) }</p>
               <p><b>Metarial Used </b>{ pro.Metarial }</p>
               
               <p><b>SKU: </b> {pro.Sku}</p>
               <p><b>Size: </b>{pro.SizeList.SizeName}</p>
               <p><b>Color: </b>{pro.ColorList.ColorName}</p>
               <p><b>Weight: </b>{pro.Weight}</p>
                
                 { localStorage.getItem("lang")==='true' ?(
                        <p>
                        <b>USD: &#36; {pro.PriceUSD}</b>
                        </p>
                 ):
                 (
                  <p>
                       <b>BDT: &#2547; {pro.PriceBDT}</b>
                       </p>
                )
          }
              
               <div className="row">
                <div className="col-md-6">
                 <b>QTY</b> <input value={quantity}  onChange={(e) => setQuantity(e.target.value)} step="1" type="number" style={{width:"50px", textAlign:"center"}} /><b> EACH</b>
                 </div>
                 <div className="col-md-6">
                 <input style={{float:"right"}} type="Button"  onClick={() => addToBuyNow(pro.ProductId, pro.ProductName, localStorage.getItem("lang")==='true' ? pro.PriceUSD : pro.PriceBDT,pro.ImageList[0]?.Address )} className="btn btn-primary"  value="BUY NOW"/>
            
                 </div>
               </div>

               <p style={{color:"red", paddingTop:"10px"}}><b> { pro.IsGlobal ? "Only available in Bangladesh" : ""} </b>
               
             
        
               </p>
               <p>
                 <button className="btn btn-secondary">
                  
                   {/* {(pro.InventoryList.length>0 ? pro.InventoryList[0].Quantity :0)-(pro.OrderDetailList?.reduce((a,v) =>  a = a + v.Quantity , 0 ))>0 ? "Stock Available":"Stock Out" } */}
                  {pro.IsStock && Number(pro.Quantity)>0 ?  "Stock Available ("+pro.Quantity+")":"Stock Out"}
                 </button>
                 
               
                 </p>


            
               <div className="mt-4 mb-4"><input type="Button"  onClick={() => addToBag(pro.ProductId, pro.ProductName, localStorage.getItem("lang")==='true' ? pro.PriceUSD : pro.PriceBDT,pro.ImageList[0]?.Address )} className="btn btn-primary"  value="Add to Cart"/> 
               
               
               <input type="Button" className="btn btn-primary" onClick={() => addToWishlist(pro.ProductId, pro.ProductName, pro.PriceUSD,pro.ImageList[0]?.Address )} value="Add to Wishlist"/>
                 
               </div>
            </Col>
            <Col md={3}>
            <div style={{"background":"#f7f7f9" , padding:"5px"}}>
            <h4>Reviews({pro.ReviewList.length})</h4>    
            <hr></hr>         
            {pro &&
             pro.ReviewList.slice(0,2).map((rv) => (
             <>
             <div style={{"color":"orange"}}>
              {Array(rv.Rating).fill(1).map((el, i) =>
               <Star fontSize="medium" />
              )}
             
            </div>
            <div style={{"borderBottom":"1px solid", "paddingBottom":"5px"}}>
             {rv.ReviewString}
             <br></br>
             By <u style={{"color":"blue"}}>{rv.Name}</u>
             </div>
            
            </>
            
            ))}
             <a  className="pull-right" onClick={handleShow} href="#">View all</a>
             <br></br>
             </div>
             <div style={{"background":"#f7f7f9" , padding:"5px"}}>
             
             <h4>Estimated Delivery</h4>
             <p>
              <b>Inside Dhaka:</b> <span className="pull-right"> Up to 5 days </span>
              </p>
              <p>
              <b>Outside Dhaka:</b> <span className="pull-right"> Up to 10 days </span>
              </p>
              </div>
             <div style={{"background":"#f7f7f9" , padding:"5px"}}>
             
                <h4>Delivery</h4>
                <p>
                 <b>Inside Dhaka:</b> <span className="pull-right"> ৳60 </span>
                 </p>
                 <p>
                 <b>Outside Dhaka:</b> <span className="pull-right"> ৳120 </span>
                 </p>
                 </div>
            </Col>
          </Row>
          </>
          ):""}    
       

        <CardStyle>
          {product &&
            product.filter(s=>s.SubCategoryId==pro.SubCategoryId).slice(0,4).map((pro) => (
              <ItemCard
                key={pro.id}
                id={pro.id}
                pName={pro.ProductName}
                image={pro.ImageList[0]?.Address}
                rating={pro.ReviewList.reduce((a,v) =>  a = a + v.Rating , 0 )>0 ? (pro.ReviewList.reduce((a,v) =>  a = a + v.Rating , 0 )/pro.ReviewList.length).toFixed(1):'0'}    
                price={localStorage.getItem("lang")==="true" ? pro.PriceUSD-pro.DiscountUSD: pro.PriceBDT-pro.DiscountBDT }
  
                originalPrice={ localStorage.getItem("lang")==="true"  ?  pro.PriceUSD:pro.PriceBDT }
                slug={pro.Slug}
              />
            ))}
         
        </CardStyle>
        </Container>
      </Styles>
    </div>

    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Review</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
        <div className="form-group">
                  <label>Rating</label>
                  <input type="text"  placeholder="0-5" max={5}  onChange={(e) => setRate(e.target.value)} className="form-control"/>
                </div>
                <div className="form-group">
                  <label>Comments</label>
                  <textarea   onChange={(e) => setComments(e.target.value)} type="text" className="form-control"></textarea>
                </div>

        {pro &&
             pro.ReviewList.map((rv) => (
             <>
             <div style={{"color":"orange"}}>
              {Array(rv.Rating).fill(1).map((el, i) =>
               <Star fontSize="medium" />
              )}
             
            </div>
            <div style={{"borderBottom":"1px solid", "paddingBottom":"5px"}}>
             {rv.ReviewString}
             <br></br>
             By <u style={{"color":"blue"}}>{rv.Name}</u>
             </div>
            
            </>
            
            ))}
            
              
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary"  onClick={postReview}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  );
}

export default ProductDetails;

const BREADCRUMB = styled.div`
  background: #b5b0b0;
  button {
    padding: 1rem 2rem;
    color: #000;
    font-weight: bolder;
    background: #b5b0b0;
    outline: none;
    &:hover,
    &:focus {
      background: #b5b0b0;
      box-shadow: inset 19px 19px 37px #a19f74, inset -19px -19px 37px #ffffe6;
      outline: none;
    }
  }
  a {
    color: #000;
    text-decoration: none;
    &:hover,
    &:focus {
      color: rgba(255, 255, 255, 0.5);
    }
  }

`;

const CardStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 2.5rem auto 0 auto;

  @media (max-width: 990px) {
    width: 100%;
    margin: auto;
    margin-top: 2.5rem;
    width: 100%;
    justify-content: space-evenly;
  }
`;

const Gallery = styled.ul`
padding: 15px;
.gallery {
  display: inline;
  list-style: none;
  width: 70px;
  min-height: 70px;
  float: left;
  margin: 0 5px 5px 0;
  text-align: center;
  border: 1px solid gray;
}

`;

const Styles = styled.div`
  margin-top: 3rem;
`;
