import React, { Component } from 'react';

import AdminLTE, { Sidebar, Content, Row, Col, Box, Button } from 'adminlte-2-react';
import styled from "styled-components";
import Profile from "../admin/Profile";
import Setting from "../admin/Setting";
import OrderHistory from "../admin/OrderHistory";
import Logout from "../admin/Logout";
import CartMenu from "../admin/CartMenu";
import WishList from "../admin/WishList";
import Shopping from "../admin/Shopping";
import * as Icon from 'react-bootstrap-icons';


const { Item } = Sidebar;

class Dashboard extends Component {
  state = {}

  render() {
    return (
    <Content title="Dashboard"  browserTitle="Dashboard">
    <div className="row">
<div className="col-12 col-sm-6 col-md-3">
<div className="info-box">
<span className="info-box-icon bg-info elevation-1"> <Icon.Cart/> </span>
<div className="info-box-content">
<span className="info-box-text">Order Completed</span>
<span className="info-box-number">
0

</span>
</div>

</div>

</div>

<div className="col-12 col-sm-6 col-md-3">
<div className="info-box mb-3">
<span className="info-box-icon bg-danger elevation-1"><Icon.Circle /></span>
<div className="info-box-content">
<span className="info-box-text">Order Pending</span>
<span className="info-box-number">0</span>
</div>

</div>

</div>


<div className="clearfix hidden-md-up"></div>
<div className="col-12 col-sm-6 col-md-3">
<div className="info-box mb-3">
<span className="info-box-icon bg-success elevation-1"><Icon.Cash /></span>
<div className="info-box-content">
<span className="info-box-text">Payment Pending</span>
<span className="info-box-number">0</span>
</div>

</div>

</div>

<div className="col-12 col-sm-6 col-md-3">
<div className="info-box mb-3">
<span className="info-box-icon bg-warning elevation-1"><Icon.Trash /></span>
<div className="info-box-content">
<span className="info-box-text">Cancellation</span>
<span className="info-box-number">0</span>
</div>

</div>

</div>

</div>
      {/* <Row>
        <Col xs={6}>
          <Box title="My first box" type="primary" closable collapsable footer={<Button type="danger" text="Danger Button" />}>
            Hello World
          </Box>
        </Col>
        <Col xs={6}>
          <Box title="Another box">
            Content goes here
          </Box>
        </Col>
      </Row> */}
    </Content>
    );
  }
}


class Admin extends Component {

  
 
  sidebar = [
   
    <Item icon="fas-square" key="dashboard" text="Dashboard"  to="/user/dashboard" />,
    <Item icon="fas-user" key="myprofile" text="My Profile" to="/user/profile" />,
   
    <li><CartMenu/></li>,
    <li><WishList/></li>,
    <Item icon="fas-list" key="orderhistory" text="Order History" to="/user/OrderHistory" />,
    <Item key="Setting" text="Change Password" to="/user/setting" />,
    //<Item icon="fa-sign-out-alt" key="Logout"  text="Logout"><Logout/></Item>,
    <li><Logout/></li>, 
    <li><Shopping/></li>, 
  ]

  
  

  render() {
    return (
       <Styles>
        <AdminLTE homeTo="/user/dashboard" title={["Sally", " Ann"]} titleShort={["SA", "BD"]} theme="blue" sidebar={this.sidebar}>
          <Dashboard path="/user/dashboard" />
          <Profile path="/user/profile" />    
          <Setting path="/user/setting" />     
          <OrderHistory path="/user/orderhistory" />   
        
        </AdminLTE>
      </Styles>
    );
  }
}

export default Admin;

const Styles = styled.div`

  .sidebar-toggle {
    padding: 0px;
  }
  .navbar{
    justify-content: left;
  }
  .main-header .sidebar-toggle{
    padding: 10px 10px;
  }
`;