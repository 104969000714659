import React from "react";
import { Container,Row, Image } from "react-bootstrap";
//import { AlignCenter } from "react-bootstrap-icons";
import styled from "styled-components";

function Row2(props) {
  return (
    <Styles >
    <Container >
       <Row>
        <h1 style={{ color: "white"  }} className="ftext">
          FEATURED PRODUCTS
          </h1>
       
        {/* <p className="pr">
      Behind every product from Sally Ann is a unique story of dignity, independence and hope.
      Others is The Salvation Army's global social enterprise linking local markets with global opportunities.
      Others partners with artisans in Kenya and Bangladesh.
      </p> */}
      </Row>
    </Container>
    </Styles>
  );

}

export default Row2;

const Styles = styled.div`
   background:#8FA2AF;
   margin-bottom:8px;
  .ftext{
    margin-top:5px;padding-top:5px;
     width:100%;
    // font-size: 1.5rem;
    text-align: center;
    padding: 10px 30px 10px 350px;
  
  }
  .pr{
    text-align:center;
    padding: 0px 30px 20px 30px;
    
  }
`;
