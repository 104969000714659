import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Fade, Zoom } from "react-reveal";
import getData from "../Getdata";
import { CardDeck, Container } from "react-bootstrap";
import SiteHeader from "./SiteHeader";

// COMMPONENTS IMPORTS FROM SUBS
import { ImageGrid } from "../Spinner";
import Row0 from "./subHome/Row0";
import Row1 from "./subHome/Row1";
import Slide from "./subHome/Slide";
import Row3 from "./subHome/Row3";
import Row4 from "./subHome/Row4";

import Client from "./subHome/Client";
import Mission from "./subHome/Mission";
import { useMediaQuery } from 'react-responsive';
import { useStateValue } from "../context/State";



const Home = () => {
  const [{}, dispatch] = useStateValue();

  const { loading } = getData("basics");


  useEffect(() => {
  
    const timing = setTimeout(() => {
      dispatch({
        type: "SET_USER",
        user: localStorage.getItem("uid")
      });
   // alert('test');
    }, 800);
    return () => clearTimeout(timing);
  }, []);

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 })
    return isDesktop ? children : null
  }


  return (
    
    <>
    <SiteHeader/>
    <div style={{ marginTop: "4rem" }}>
      {loading ? (
        <ImageGrid />
      ) : (
        <Styles>
           <Desktop>
          <Fade>
            <Slide />
          </Fade>
          </Desktop>
          
      {loading==false ?
         (
          <Fade big>
      
       
         
            <WhiteSpace></WhiteSpace>
            <Row3 />
            <WhiteSpace></WhiteSpace>
            <Row1/>
       
            <WhiteSpace></WhiteSpace>
            <Zoom>
              <Row4 />
            </Zoom>
            <WhiteSpace></WhiteSpace>
            <h1 style={{textAlign:"center", background:"#8FA2AF", textTransform:"uppercase"}}>Our Customers</h1>
            <WhiteSpace></WhiteSpace>
            <Client />
            <WhiteSpace></WhiteSpace>
           <SmallSpace></SmallSpace>
            <div style={{ background:"#8FA2AF"}}>
            <SmallSpace></SmallSpace>
            <h1 style={{textAlign:"center", color: "white", textTransform:"uppercase"}}>Mission Statement</h1>
            <Mission />
            <SmallSpace></SmallSpace>
            </div>
            
           
          
         
    
          </Fade>
      ):""

          }

        </Styles>
      )}
    </div>
   
    </>
   
  );
};

export default Home;

const Styles = styled.div`
  /* padding: 0 12% 0 11%; */
  color: #ffff;
  .card-deck,
  .container {
     display: flex;
    text-align: center;
    
  }
  
`;

const WhiteSpace = styled.div`
  height: 2.3rem;
  @media (min-device-width: 320px) and (max-device-width: 480px) {
    height: 0.7rem;
  }
  @media (min-device-width: 768px) and (max-device-width: 1024px) {
    height: 1.3rem;
  }
`;

const SmallSpace = styled(WhiteSpace)`
  height: 1.8rem;
  @media (min-device-width: 320px) and (max-device-width: 480px) {
    height: 0.5rem;
  }
  @media (min-device-width: 768px) and (max-device-width: 1024px) {
    height: 1rem;
  }
`;
