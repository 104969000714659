import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import getCat from "../../Getcat";
import { Container, Row, Col, Image } from "react-bootstrap";
import config from '../../config/config';

function Row3() {
  const { category } = getCat("categories");
  return (
    <Styles>
      <Container style={{"marginTop":"2rem"}}>
        <Row>
          {category &&
            category.map((cat) => (
              <Col key={cat.id} style={{ paddingBottom: "1rem" }} md={3}>
                <Link to={`/product/${cat.Slug}/`} className="imageOver">
                  <Image style={{width:"100%"}} key={cat.id} src={config.API()+cat.Image} thumbnail />
                  <span className="centered">{cat.CategoryName}</span>
                </Link>
              </Col>
            ))}
        </Row>
      </Container>
    </Styles>
  );
}

export default Row3;

const Styles = styled.div`
  .img-thumbnail {
    border: none;
  }
  .imageOver {
    position: relative;
    text-align: center;
    color: white;
  }
  
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    padding:5px;
    background-color:#fff;
    transform: translate(-50%, -50%);
    color:#000;
  }
`;
