import React from "react";
import * as Icon from 'react-bootstrap-icons';

function CartMenu() {
  

  const cartmenu = () => {

 // localStorage.removeItem("uid");   
 // history.push("/");
 window.location.href="/cart";

  };

  return (
    
          
            <a href="#"  onClick={cartmenu}><Icon.Cart /> &nbsp; Cart</a>
        
  );
}

export default CartMenu;
