import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import Menu from "./Menu";




function Banner_menu() {
  return (
    <Styles>
      <Navbar
        expand="lg"
        style={{
          background: "#f8f8f8",
          display: "flex",
          padding: "0px",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        {/* <Space></Space> */}
        

        {/* <Space></Space>  */}
        <Menu />
      </Navbar>
    </Styles>
  );
}

export default Banner_menu;

// STYLED COMPONENTS

const Styles = styled.div`
  top: 0;
  z-index: 1;
  // position: fixed;
  width: -webkit-fill-available;
  width: -moz-available;
  box-shadow: 0 4px 6px #18181844;
  
  .navbar-light .navbar-nav .nav-link {
    color: #000;
    font-size: 12px;
    font-weight: bold;
  }
  #navbarContent{
    display:block !important ;
  }
  .nav-link{
    padding-top:22px;
    padding-bottom:22px;
    padding-right: 15px !important;
    padding-left: 15px !important;
    position: relative;
  }
 
  .nav-item a:hover{
    border: 1px solid #000;
        padding-top: 21px;
        padding-left: 14px !important;
        padding-right: 14px !important;
  }
  .form-inline {
    @media (max-device-width: 990px) {
      padding-left: 0;
    }
  }
  // @media (min-width: 1200px){
   
  //     margin-right:-150px;
  //     margin-left:-150px;
    
  // }
  .form-control {
    ::placeholder {
      font-size: 10px;
      line-height: 12;
      overflow: visible;
    }
  }
`;
const Space = styled.div`
  width: 12%;
  height: 100%;
  @media (min-device-width: 320px) and (max-device-width: 480px) {
    width: 0;
    height: 0;
  }
  @media (min-device-width: 768px) and (max-device-width: 1024px) {
    width: 0;
    height: 0;
  }
  
`;
