import React from "react";
import * as Icon from 'react-bootstrap-icons';

function WishList() {
  

  const wishlist = () => {

 // localStorage.removeItem("uid");   
 // history.push("/");
 window.location.href="/wishlist";

  };

  return (
    
          
            <a href="#"  onClick={wishlist}><Icon.Heart /> &nbsp; Wishlist</a>
        
  );
}

export default WishList;
