import React from "react";
import styled from "styled-components";
import getData from "../../Getdata";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image } from "react-bootstrap";
import config from '../../config/config';
import * as Icon from 'react-bootstrap-icons';

function Row0() {
  const { banner } = getData("banners");
  return (
    <Styles>
      <div>
        <Row
        className="fixed-bg"
        style={{
          background:" url('/images/header image.jpg')",
          backgroundSize:"100%"
         
        }}>
         
              <Col  xs={12}>
                <Container>
                  <div className="headertxt">           
                    
                    DIGNITY <br/> INDEPENDENCE<br/> HOPE   
                    <p className="para mt-5">
                  Others is a Salvation Army initiative using fair trade principles <br/> 
                  to achieve job creation, empowerment and poverty alleviation.<br/>
                   Our products are hand made by producers affiliated with<br/>
                   The Salvation Army’s community work in Bangladesh and Kenya.
                  </p>      

                  <a target="_blank" className="btn btn-primary" href="https://www.tradeforhope.com/about-others" >
                    Learn More <Icon.ArrowRightShort/>
                    </a>           
                  </div>
          
                </Container>
              </Col>
           
        </Row>
      </div>
    </Styles>
  );
}

export default Row0;

const Styles = styled.div`
  .img-thumbnail {
    border: none;
  }
  @media (min-width: 1200px){
    .row{
      margin-right:auto;
      margin-left:auto;
    }
  }

  .fixed-bg {
    background-attachment: fixed !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center center !important;
    height: 30vw !important; 
    width: 100% !important;
    color: white;
    text-align: center !important;
    display: table;
  }
  .headertxt {
    font-size: 4em;
    line-height: 1em;
    padding-top: 5%;
    font-family: fjalla one;
    color: #fff;
    text-align: left;
}
.para{
  font-size:12px;
  line-height: 1.5;
}
`
