import { useState, useEffect } from "react";
import config from './config/config';


function GetData(collection) {
  //  const [docs, setDocs] = useState([]);
   // const [product, setProduct] = useState([]);
    // const [category, setCategory] = useState([]);
    // const [tag, setTag] = useState([]);
    // const [countries, setCountry] = useState([]);
    // const [districts, setDistrict] = useState([]);
    const [basics, setBasic] = useState([]);

    const [loading, setLoading] = useState(true);


    useEffect(() => {
        setLoading(true);
        fetch(config.API()+"api/Apibanner")
        .then((response) =>response.json())           
        .then((data) => {       
              setBasic(data.basics);          
              setLoading(false);         
             
               
        })

    }, [collection]);

    return { basics,loading};
}

export default GetData;

