import React, { useState } from "react";
import styled from "styled-components";

import { Link, useHistory } from "react-router-dom";
import { Lock,Phone, Person } from "@material-ui/icons";
import config from '../../config/config';
import { useStateValue } from "../../context/State";

function ForgetPassword() {
  const history = useHistory();
  const [{}, dispatch] = useStateValue();
  const [otp, setOtp] = useState("");

  const forgetPassword = (e) => {
    e.preventDefault(); 
    
    var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({
            "PhoneNumber":otp
          
        }),
          redirect: 'follow'
        };

        fetch(config.API()+"api/ApiForgetPassword", requestOptions)
          .then(response => response.json())
          .then(msg =>{
            if(msg!=null){
            
              if(window.confirm(JSON.parse(msg).msg) == true) {
                history.push('/');
              }
         
          }
          else{
            alert('Error Occered');
          }
          })
          .catch(error => alert('User Id or Password is Incorrect'));
  };
  return (
    <>
     
      <Styles>
        <div className="content">
          <div className="text">
          <img src="/images/logo.png" width="250" />
            <br/>
            <br/>
            <h1>Change Password</h1>           
            <p>Enter Your Phone Number or Email</p>
          </div>
          <form action="#">
            
            <div className="field">
              <Phone
                style={{ position: "absolute", bottom: "14px", left: "1rem" }}
              />
              <input
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                type="text"
                placeholder="Phone Number or Email"
              />
            </div>
      
            <button onClick={forgetPassword}>Send Password</button> 
            {/* <div className="signup">
              Please Login 
              <Link to="/login"> Click here</Link>
            </div> */}
          </form>
        </div>
      </Styles>
    </>
  );
}

export default ForgetPassword;

const Styles = styled.div`
  display: flex;
  justify-content: center;

  .content {
    width: 400px;
    background: #ffff;
    margin-top: 80px !important;
    // border-radius: 15px;
    padding: 40px 30px;
    box-shadow: 0 22px 40px rgba(17, 96, 96, 0.2);
  }
  .text {
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 0.5rem;
    h1 {
      font-size: 22px;
      font-weight: bold;
    }
    h3 {
      font-size: 18px;
      font-weight: bold;
    }
    p {
      font-size: 10px;
    }
  }
  .field {
    height: 50px;
    width: 100%;
    display: flex;
    position: relative;
    input {
      height: 100%;
      width: 100%;
      padding-left: 45px;
      font-size: 18px;
      outline: none;
      border: 1px solid;
      color: #595959;
      // background: #dde1e7;
       border-radius: 5px;
      // box-shadow: inset 2px 2px 5px #babecc, inset -5px -5px 10px #ffffff73;
      &:focus ~ label {
        box-shadow: inset 2px 2px 5px #babecc, inset -1px -1px 2px #ffffff73;
      }
    }

    &:nth-child(2) {
      margin-top: 20px;
    }

    span {
      position: absolute;
      width: 50px;
      line-height: 50px;
      color: #595959;
    }

    label {
      position: absolute;
      top: 50%;
      left: 45px;
      pointer-events: none;
      color: #666666;
      transform: translateY(-50%);
    }

    .field input:focus ~ label {
      opacity: 0;
    }
  }

  .forgot-pass {
    text-align: left;
    margin: 10px 0 10px 5px;
    a {
      font-size: 16px;
      color: #3498db;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
  button {
    margin: 15px 0;
    width: 100%;
    height: 50px;
    color: #000;
    font-size: 18px;
    font-weight: 600;
    background: #dde1e7;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 2px 2px 5px #babecc, -5px -5px 10px #ffffff73;

    &:focus {
      color: #3498db;
      box-shadow: inset 2px 2px 5px #babecc, inset -5px -5px 10px #ffffff73;
    }
  }

  .signup {
    font-size: 16px;
    color: #595959;
    margin: 10px 0;
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
        color: #000;
      }
    }
  }
`;
