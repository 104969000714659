import { useState, useEffect } from "react";
import config from './config/config';


  
function GetGen(collection) {
   
   
      const [tag, setTag] = useState([]);
      const [countries, setCountry] = useState([]);
      const [districts, setDistrict] = useState([]); 
      const [loading, setLoading] = useState(false);
  
  
      useEffect(() => {
          setLoading(true);
          fetch(config.API()+"api/Apigeneral")
          .then((response) =>response.json())           
          .then((data) => {       
                
               
                setCountry(data.countries);
                setDistrict(data.districts);               
                setTag(data.tags);             
                setLoading(false);
                
               
                 
          })
  
      }, [collection]);
  
      return { countries ,districts,tag,loading};
  }
  
  export default GetGen;