import React from "react";
import styled from "styled-components";
import getData from "../Getdata";

import { Container, Image } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";

import SiteHeader from "./SiteHeader";

import parse from 'html-react-parser';
import Breadcrumb from 'react-bootstrap/Breadcrumb'


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(0),
    },
  },
}));

function Contact() {
  const { basics } = getData("basics");
  let about= basics.find(s=>s.BasicTypeId==7);

  return (
    <>
    <SiteHeader/>
    <div>
      <Styles>
          {/* <Fade>
            <Row0 />
          </Fade>
          <Fade>
            <Banner_menu />
          </Fade> */}
         
        <Container style={{marginTop:"90px"}}>
        <div className="row">
             <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          
            <Breadcrumb.Item active>Contact Us</Breadcrumb.Item>
          </Breadcrumb>
          </div>
          <div className="row">
          <h3>{about?.Title}</h3>          
        
          </div>
          <div className="row" >
            365/2, Lane 6 (west), DOHS Baridhara, Dhaka-1206.<br/>
            Tel: 880-2-8411755-6<br/>
            Fax: 880-2-8411757<br/>
            e-mail: info@ban.salvationarmy.org <br/> 
            <br/> 
            </div>
          <div className="row">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1235.816835066208!2d90.41097233472357!3d23.812443750479495!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c6ff9af3bf19%3A0x1d3e31f6ef3d0701!2sSally%20Ann%20Shop!5e0!3m2!1sen!2sbd!4v1642604956509!5m2!1sen!2sbd" width="100%" height="450" allowfullscreen="" loading="lazy"></iframe>
          </div>
        </Container>
      </Styles>
    </div>
    </>
  );
}

export default Contact;

const BREADCRUMB = styled.div`
  background: #b5b0b0;
  button {
    padding: 1rem 2rem;
    color: #000;
    font-weight: bolder;
    background: #b5b0b0;
    outline: none;
    &:hover,
    &:focus {
      background: #b5b0b0;
      box-shadow: inset 19px 19px 37px #a19f74, inset -19px -19px 37px #ffffe6;
      outline: none;
    }
  }
  a {
    color: #000;
    text-decoration: none;
    &:hover,
    &:focus {
      color: rgba(255, 255, 255, 0.5);
    }
  }
`;

const CardStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 2.5rem auto 0 auto;

  @media (max-width: 990px) {
    width: 100%;
    margin: auto;
    margin-top: 2.5rem;
    width: 100%;
    justify-content: space-evenly;
  }
`;

const Title = styled.div`
  position: relative;
  top: 1.5rem;
`;

const Styles = styled.div`
  margin-top: 3rem;
`;
