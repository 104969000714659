import React, { useState } from "react";
import styled from "styled-components";
import getData from "../Getdata";
import { Container, Image,Modal, Button  } from "react-bootstrap";
import SiteHeader from "../components/SiteHeader";
import parse from 'html-react-parser';
import { Link ,useParams } from "react-router-dom";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import config from "../config/config";

import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

function Story() {
  const { basics } = getData("basics");
  const { slug } = useParams(); 

  let about= basics.find(s=>(s.Title||'').toLowerCase() ==slug.replace(/-/g, ' '));  


  const images = [  
  ];

  if(about)
  { about.BasicDetails.map((cat) => (
    images.push({
      original:config.API() +cat.ImageURL,    
      thumbnail:config.API() +cat.ImageURL
    })
    )) 
  }


  return (
    <>
    <SiteHeader/>
    <div>
      <Styles>         
         
        <Container style={{marginTop:"90px"}}>
        <div className="row">
             <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          
            <Breadcrumb.Item active>Story</Breadcrumb.Item>
          </Breadcrumb>
          </div>
          <div className="row">
         
            {/* <div className="col-md-12">
              <img src={config.API()+about?.ImageURL} style={{width:"100%"}}/>
              </div> */}
           
            <div className="col-md-6">

            <h3>{about?.Title}</h3>
            
            <p>
             {parse(about?.Content!=null? about?.Content :"")}
             
            </p>
           
         
            </div>
            <div className="col-md-6" style={{"marginTop":"50px"}}>
            <div className="row">
            
           
            <ImageGallery showNav={true} items={images} />


             </div>
            </div>
          </div>
        </Container>
      </Styles>
    </div>

    
    </>
  );
}

export default Story;

const BREADCRUMB = styled.div`
  background: #b5b0b0;
  button {
    padding: 1rem 2rem;
    color: #000;
    font-weight: bolder;
    background: #b5b0b0;
    outline: none;
    &:hover,
    &:focus {
      background: #b5b0b0;
      box-shadow: inset 19px 19px 37px #a19f74, inset -19px -19px 37px #ffffe6;
      outline: none;
    }
  }
  a {
    color: #000;
    text-decoration: none;
    &:hover,
    &:focus {
      color: rgba(255, 255, 255, 0.5);
    }
  }
`;

const CardStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 2.5rem auto 0 auto;

  @media (max-width: 990px) {
    width: 100%;
    margin: auto;
    margin-top: 2.5rem;
    width: 100%;
    justify-content: space-evenly;
  }
  
`;

const Title = styled.div`
  position: relative;
  top: 1.5rem;
`;


const Styles = styled.div`
  margin-top: 3rem;
`;
