import React,{useState} from "react";
import styled from "styled-components";

import config from '../config/config';
import {
  Col,
  Container,
  Row,
  ListGroup,
  Form,
  InputGroup,
  Image,
} from "react-bootstrap";
import { Height } from "@material-ui/icons";


const RightNav = ({ open }) => {

 }
function Footer() {
  const [email, setEmail] = useState("");
  
  const subscribes = (e) => {
    e.preventDefault();
  //  alert(email);
    if (email!="") {
      
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        var urlencoded = new URLSearchParams();      
        urlencoded.append("Email", email); 
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: urlencoded,
          redirect: 'follow'
        };

        fetch(config.API()+"api/Subscribe", requestOptions)
          .then(response => response.json())
          .then(result =>{

           let rt=JSON.parse(result);         
            if(rt.msg=="Submit Successful"){
              
              alert('Successfully Subscribe');
             
            }
          })
          .catch(error => alert("User Already Exist!"));
    } else {
      alert("Email must be Input");
    }
  };

  return (
    <Container fluid style={{ padding: "0", paddingTop: "5rem" }}>
      <FOOTER>
        <section>
          <Row>
         
            
            <Col sm={6} md={2}>
              <Heading>COMPANY</Heading>
              <ListGroup>
                <Ul>
                  <a href="/about">About Us</a>
                </Ul>
                <Ul>
                  <a href="/contact">Contact Us</a>
                </Ul>
                <Ul>
                  <a href="/terms-condition">Terms and Condtions</a>
                </Ul>
                <Ul>
                  <a href="/privacy-policy">Privacy Policy</a>
                </Ul>
                 <Ul>
                  <a href="/return-policy">Return & Refund Policy</a>
                </Ul> 
              </ListGroup>
            </Col>
            <Col sm={3} md={4}>
              <Heading>CONNECT WITH US</Heading>
              <ListGroup>
                <Ul>
                Shop Address-1: 365/2, Lane 6  (west), DOHS Baridhara, Dhaka-1206.
                <br/>
                <br/>
                
                </Ul>
                
                <Ul>
                Shop Address-2:<br/>Shop-7, Gulshan Cirlce-1, Navana tower Complex,
             
                </Ul>
                <br/>
                <Ul>
                Tel: 880-2-8411755-6
                </Ul>
                <Ul>
                
                  Fax: 880-2-8411757
                <br/>
                
                  e-mail: contact@sallyannbd.com
                  <br/>
                  <br/>
                  TIN Number: 266059844778
                  <br/>
                  Trade License No. : TRAD/DNCC/025551/2022
                  
                  <br/>
                  
                  
                </Ul>
                
              </ListGroup>
            
            </Col>
            
            <Col sm={6} md={3}>
              <Heading style={{ paddingBottom: "1rem" }}>
                KEEP UP TO DATE
              </Heading>
              <ListGroup>
                <Ul>
                  <Form inline>
                    <InputGroup>
                      <Emailinput name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter Mail Id"></Emailinput>
                      <br/>
                      <br/>
                      <InputGroup.Prepend>
                        <BUTTON  type="button" onClick={subscribes}>SUBSCRIBE</BUTTON>
                      </InputGroup.Prepend>
                    </InputGroup>
                  </Form>
<br/>
<br/>
<br/>
<br/>
                  <li className="nav-item hv">
            <a className="nav-link font-weight-bold" target="_blank" href="https://www.facebook.com/sallyannbangladesh">
          <Image padding="0px" height="80px" width="80px"  style={{ padding: 20  }} src="https://usa.tradeforhope.com/common/prodesc/images/FB3.png"/>
          {/* <Image padding="0px" height="40px" width="40px" src="https://usa.tradeforhope.com/common/prodesc/images/instagram.png"/> */}
          
          </a>
        </li>
        {/* <li className="nav-item hv">
          
          <a className="nav-link font-weight-bold" to="/">
           <Image padding="0px" height="40px" width="40px" src="https://usa.tradeforhope.com/common/prodesc/images/instagram.png"/>
          </a>
        </li> */}
                </Ul>
              </ListGroup>
             
            </Col>
            
              
       
           
            <Col sm={6} md={3}>
             <h4>payment channels</h4>
             <img src="/images/sslcomerz.png" style={{width:"100%"}}/>
            </Col>
           
          </Row>
        </section>
        
        {/* <section style={{ paddingTop: "4rem" }}>
          <Row>
            <Col sm={6} md={3}>
              <Heading>CUSTOMER SERVICE</Heading>
              <ListGroup>
                <Ul>
                  <small>
                    <History fontSize="small" />
                  </small>
                  <Link style={{ paddingLeft: "5px" }} to="/coming-soon">
                    <big>15 Days Return Policy</big>
                  </Link>
                </Ul>
                <Ul style={{ display: "flex" }}>
                  &nbsp;<h5>₹</h5>
                  <Link style={{ paddingLeft: "5px" }} to="/coming-soon">
                    &nbsp; <big> Cash On Delivery</big>
                  </Link>
                </Ul>
              </ListGroup>
            </Col>
            <Col sm={6} md={3}>
              <Heading>DOWNLOAD THE APP</Heading>
              <ListGroup>
                <Ul>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    style={{ paddingRight: ".5rem" }}
                    href="https://play.google.com/store/apps/details?id=com.bewakoof.bewakoof&hl=en"
                  >
                    <Image
                      width="100px"
                      src="https://images.bewakoof.com/web/app_android_v1.png"
                      alt="sbjb"
                      thumbnail
                    />
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://apps.apple.com/in/app/bewakoof/id1100190514"
                  >
                    <Image
                      width="100px"
                      src="https://images.bewakoof.com/web/app_ios_v1.png"
                      alt="sbjb"
                      thumbnail
                    />
                  </a>
                </Ul>
              </ListGroup>
            </Col>
            <Col style={{ padding: "8px 10px" }} sm={12} md={6}>
              <Heading>100% Secure Connection</Heading>
              <Ul>
                <Image
                  width="300px"
                  src="https://images.bewakoof.com/web/secure-payments-image.png"
                  alt="sbjb"
                  fluid
                />
              </Ul>
            </Col>
          </Row>
        </section> */}
      </FOOTER>
      <DevInfo>
        <div>
          <h5>
            © All Rights Reserved  by <a href="https://sallyannbd.com" target="_blank">Sally Ann Bangladesh Ltd.</a>
           
          </h5>
        </div>
      </DevInfo>
    </Container>
  );
}

export default Footer;



const DevInfo = styled.div`
  background:#9e9696;
  padding: 1rem;
  div {
    max-width: 1280px;
    margin: 0px auto;
    width: 90%;
    text-align: center;
  }
  h5 {
    color: #1b1b1b;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }
  .neon {
    color: #fb4264;
    font-size: 16px;
    text-shadow: 0 0 3px #f40a35;
    animation: neon 1s ease infinite;
    -moz-animation: neon 1s ease infinite;
    -webkit-animation: neon 1s ease infinite;
    @keyframes neon {
      0%,
      100% {
        text-shadow: 0 0 2px #fa1c16, 0 0 3px #fa1c16, 0 0 10px #fa1c16,
          0 0 10px #fa1c16, 0 0 1px #fed128, 1px 1.2px 1px #806914;
        color: #fed128;
      }
      50% {
        text-shadow: 0 0 3px #800e0b, 0 0 1.5px #800e0b, 0 0 5px #800e0b,
          0 0 5px #800e0b, 0 0 0.2px #800e0b, 0.4px 1px 1px #40340a;
        color: #806914;
      }
    }
  }
  a {
    &:hover {
      text-decoration: underline;
    }
  }
`;

// Footer Section
const FOOTER = styled.div`
  padding: 2% 12% 2% 11%;
  background-color: #181818;
  color: #ffff;
  a {
    margin: 0;
    padding: 0;
    font-size: 12px;
    font-weight: 500;
    vertical-align: baseline;
    background: transparent;
    color: #ffff;
    outline: none;
    &:hover {
      color: #ffff;
      text-decoration: none;
    }
  }
  .col-md-3,
  .col-sm-6 {
    padding: 8px 10px;
  }
  .list-group {
    background-color: transparent;
    color: #ffff;
    border: none;
  }
`;

const Ul = styled.ul`
  list-style-type: none;
  margin-bottom: 0px;
  margin-block-start: 0px;
  margin-block-end: 0px;
  padding-inline-start: 0px;
`;

const Heading = styled.h5`
  color: #fdb827;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px;
  display: block;
`;

const Emailinput = styled.input`
  background: transparent;
  border: 1px solid;
  border-bottom-style: solid;
  color: #ffff;
  // width: 55%;
  border-color: #fdb827;
  border-bottom-width: 1px;
  &:focus {
    outline: 0;
  }
`;

const BUTTON = styled.button`
  border: none;
  background-color: #fdb827;
  padding: 0.3rem 0.8rem;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
`;
