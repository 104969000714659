

const cart =JSON.parse(localStorage.getItem('carts')||"[]");
const wlist =JSON.parse(localStorage.getItem('wlist')||"[]");

const Storage = (bag) => {
  localStorage.setItem('carts', JSON.stringify(bag.length > 0 ? bag: []));
}

const StorageWishlist = (wishlist) => {
  localStorage.setItem('wlist', JSON.stringify(wishlist.length > 0 ? wishlist: []));
}

export const initialState = {
  bag: cart,
  user: null,
  wishlist: wlist,
};

export const getBagTotal = (bag) =>{
  //console.log(bag);
  Storage(bag);
 return bag.filter(s=>s.status==true)?.reduce((amount, item) =>  item.price * item.quantity + amount, 0);
}

const reducer = (state, action) => {
 
  switch (action.type) {
    // ADD TO WISHLIST
    
    case "ADD_TO_WISHLIST":
   //   console.log(action.item.id);
   if (!state.wishlist.find(item => item.id === action.item.id)) {
         state.wishlist.push(action.item)
    }
    else{

        state.wishlist[state.wishlist.findIndex(item => item.id === action.item.id)].quantity++
    } 
          return {
        ...state,      
        ...StorageWishlist(state.wishlist),
        wishlist: [...state.wishlist],
      };

    case "REMOVE_FROM_WISHLIST":
      const indexWish = state.wishlist.findIndex(
        (wishlistItem) => wishlistItem.id === action.item.id
      );
      let newwishlist = [...state.wishlist];
      if (indexWish >= 0) {
        newwishlist.splice(indexWish, 1);
      } else {
        console.warn(
          `Something Wrong with product (id: ${action.item.id}) maybe its not added`
        );
      }
      return {
        ...state,
        ...StorageWishlist(state.wishlist),
        wishlist: newwishlist,
      };

    // ADD TO BAG

    case "ADD_TO_BAG":
   
      if (!state.bag.find(item => item.id === action.item.id)) {
        state.bag.push(action.item)
    }
    else{
    
         state.bag[state.bag.findIndex(item => item.id === action.item.id)].quantity++
    } 
    return {
        ...state,
        ...getBagTotal(state.bag),
        bag: [...state.bag]
    }

      // const cart =JSON.parse(localStorage.getItem('carts')||"[]");
      // cart.push(action.item);
      // localStorage.setItem('carts', JSON.stringify(cart))
      // return {
      //   ...state,
      //   bag: [...state.bag, action.item],
      // };
      case "CHECKED":
        state.bag[state.bag.findIndex(item => item.id === action.item.id)].status=action.item.status
        return {
            ...state,
            ...getBagTotal(state.bag),
            bag: [...state.bag]
        }
    case "INCREASE":
        state.bag[state.bag.findIndex(item => item.id === action.item.id)].quantity++
        return {
            ...state,
            ...getBagTotal(state.bag),
            bag: [...state.bag]
        }
    case "DECREASE":
      state.bag[state.bag.findIndex(item => item.id === action.item.id)].quantity--
        return {
            ...state,
            ...getBagTotal(state.bag),
            bag: [...state.bag]
        }
    case "CLEAR":
          return {
            bag: [],
              ...getBagTotal([]),
          }
    case "REMOVE_FROM_BAG":
      const index = state.bag.findIndex((bagItem) => bagItem.id === action.id);
      let newbag = [...state.bag];
      if (index >= 0) {
        newbag.splice(index, 1);
      } else {
        console.warn(
          `Something Wrong with product (id: ${action.id}) maybe its not added`
        );
      }
      return {
        ...state,
        ...getBagTotal(state.bag),
        bag: newbag,
      };
    case "SET_USER":
      return {
        ...state,
        user: action.user,
      };
      case "LOGOUT_USER":
        return {
          ...state,
          user: null,
        };
    default:
      return state;
  }
};

export default reducer;
